[data-toggle-item]
  &.is-open
    [data-toggle-content]
      opacity: 1
      pointer-events: auto
      //height: unset
      //height: auto

[data-toggle-content]
  overflow: hidden
  height: 0
  opacity: 0
  pointer-events: none
  transition: all .3s ease