.index-insights
  color: #FFF
  background: $lh-action
  position: relative
  
  &__item
    &-image
      position: relative

      &:after
        content: ''
        display: block
        padding-bottom: 57.8666%

      img
        position: absolute
        width: 100%
        height: 100%
        object-fit: cover
        
    &-title
      margin-bottom: size(30, m)

      h4
        font-size: size(16, m)

    &-description
      font-size: size(20, m)
      line-height: 150%
      margin-bottom: size(34, m)

    &-content
      padding: size(55, m) size(16, m) size(53, m)

  @include mq($until: tablet)
    .swiper-pagination
      position: absolute
      right: 0
      left: 0
      bottom: 0
      pointer-events: none

      //&:before
        content: ''
        display: block
        padding-bottom: 57.8666%

      //&-bullet
        transform: translateY(-300%)

    &__item
      &-image
        display: none

  @include mq($from: tablet)
    &__item
      display: flex

      &-image
        flex: size(380) 0 0

        &:after
          padding-bottom: 52.631557%

      &-title
        h4
          font-size: size(16)

      &-content
        flex: 1
        display: flex
        padding: size(51) size(80) size(40) size(40)

      &-description
        font-size: size(24)
        font-family: Poppins, sans-serif
        font-weight: 500
        margin-bottom: unset

      &-info
        flex: size(646) 0 0
        margin-right: auto

      &-title
        margin-bottom: .6em

      &-button
        align-self: center

    &__list
      .swiper-pagination
        width: unset
        top: 0
        display: flex
        justify-content: center
        flex-direction: column