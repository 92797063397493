.careers-life
  padding-top: 7.2em
  padding-bottom: 2.4em

  &__title
    padding-right: 10em
    margin-bottom: 2.9em

  &__picture
    margin-bottom: 3.2em

  &__description
    margin-bottom: 6.1em

  &__list
    &-item
      border: 1px solid $lh-accent
      border-radius: size(8, m)
      margin-bottom: 4.8em
      padding: size(37, m) 1em size(42, m)
      position: relative

      &.is-open
        .careers-life__list-toggler
          &:after
            opacity: 0


    &-toggler
      width: size(48, m)
      height: size(48, m)
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      background: #063A59
      position: absolute
      top: 100%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
      cursor: pointer

      &:after, &:before
        content: ''
        display: block
        height: size(4, m)
        width: size(18, m)
        background: #FFF
        position: absolute

      &:after
        transform: rotate(-90deg)

    &-title
      font-size:  size(20, m)
      line-height: 150%
      margin-bottom: .5em
      color: $lh-accent

    &-info
      padding-top: 1em
      font-size:  size(20, m)
      line-height: 150%

    &-description
      font-size:  size(20, m)
      line-height: 150%

  @include mq($from: tablet)
    padding-top: 8.6em
    padding-bottom: 7.4em

    &__content
      display: grid
      grid-template-areas: "picture title" "picture description" "picture list"
      grid-template-columns: size(620) size(550)
      grid-template-rows: auto auto auto
      justify-content: space-between

    &__picture
      grid-area: picture
      margin-bottom: unset

    &__title
      grid-area: title
      padding-top: 4.9em
      padding-right: unset
      margin-bottom: 2em

    &__description
      grid-area: description
      margin-bottom: 3.6em

    &__list
      grid-area: list

      &-item
        padding: 2.1em 1.9em 1.9em
        margin-bottom: 3.4em

        &:last-child
          margin-bottom: unset

      &-title
        font-size: size(20)
        margin-bottom: 1.8em

      &-description
        font-size: size(20)

      &-info
        font-size: size(20)
        padding-top: 1.2em

    &__image
      position: sticky
      top: size(90)
      width: size(580)

      .img--bordered
        width: 100%

      img
        display: block
        width: 100%