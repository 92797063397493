.btn
  line-height: 1
  font-size: size(16, m)
  transition: .3s ease
  display: inline-flex
  align-items: center
  justify-content: center
  width: fit-content
  cursor: pointer
  font-weight: 600
  height: size(56, m)
  color: $lh-accent
  position: relative
  appearance: none
  border: none
  border-radius: size(28, m)
  box-shadow: none
  font-family: Poppins, sans-serif

  $btn: &

  .icon
    display: block
    fill: currentColor
    height: auto
    transition: transform .3s ease

  &__text
    padding-left: size(24, m)
    padding-right: size(24, m)
    white-space: nowrap

  &__icon
    display: flex
    align-items: center
    justify-content: center
    align-self: stretch
    border-radius: size(28, m)
    transition: .3s ease
    min-width: size(56, m)
    margin-left: -.5em

    &--green-arrow
      background: $lh-green

      .icon
        fill: #FFF

    &--action-arrow
      background: $lh-action

      .icon
        fill: #FFF

  &:hover
    color: $lh-action

    #{$btn}
      &__icon--green-arrow, &__icon--action-arrow
        transform: translateX(.5em)


  &--white
    background: #FFF
    color: $lh-action
    
    &:hover
      background: $lh-green
      color: #FFF

    &.btn--green-arrow
      background: #FFF
      color: $lh-accent

    &.btn--action-arrow
      background: #FFF
      color: $lh-accent

  &--action
    background: #006194
    color: #FFF

    &:hover
      background: $lh-green
      color: #fff

  @include mq($from: tablet)
    font-size: size(16)
    height: size(56)

    &__text
      padding-left: size(24)
      padding-right: size(24)


    &__icon
      min-width: size(56)

    .icon
      &--arrow-right
        width: size(25)