.contact-employment
  padding-top: 2em

  &__title
    margin-bottom: 3.4em

  &__list
    font-size: size(20, m)

    &-caption
      font-size: size(24, m)
      font-family: Poppins, sans-serif
      font-weight: 500
      line-height: 150%
      margin-bottom: -.1em

    &-item
      line-height: 150%
      margin-bottom: 2.8em

    .text-underline
      color: inherit
      text-decoration: underline

  @include mq($from: tablet)
    padding-bottom: 5.7em

    &__title
      margin-bottom: 2.4em
      .h3
        font-size: size(40)

    &__list
      display: flex

      &-item
        margin-bottom: unset
        font-size: size(20)

        &:not(:last-child)
          margin-right: size(100)

        &:nth-child(2)
          width: size(292)
          flex: size(292) 0 0

        &:nth-child(3)
          flex: size(552) 0 0

      &-caption
        font-size: size(24)