.choices
  margin-bottom: unset
  font-size: size(16, m)

  &__inner
    background: #FFF
    border: 1px solid #014E6B
    border-radius: size(29,m)
    min-height: unset
    height: size(58, m)
    padding: 0 0 0 3.125vw
    font-size: inherit

  &[data-type*=select-one]
    .choices__inner
      padding-bottom: unset

    &:after
      height: 1em
      width: 1em
      border: none
      mask: url("../images/sprites/main.stack.svg#image-angle-down") 50% 50% no-repeat
      mask-size: contain
      background: #000
      margin: unset
      transform: translateY(-50%)

    &.is-open
      &:after
        margin: unset
        transform: translateY(-50%) rotate(180deg)

  &__list
    &--single
      padding: unset
      display: flex
      align-items: center
      height: 100%

    &--dropdown,&[aria-expanded]
      word-break: unset
      border: none
      border-radius: unset
      box-shadow: 0.2px 20px 40px 0 rgba(1, 61, 121, 0.10)

      .choices__item
        font-size: size(16, 400)

  &.is-open
    .choices__inner
      border-radius: size(29,m)

  &.is-focused, &.is-open
    .choices__inner
      border-color: #014e6b

  &.is-flipped
    .choices__list
      &--dropdown, &[aria-expanded]
        box-shadow: 0.2px -20px 40px 0 rgba(1, 61, 121, 0.10)
        border-radius: unset
        margin-bottom: unset



  .choices__list--dropdown, .choices__list[aria-expanded]
    margin-top: unset

  @include mq($from: tablet)
    &__inner
      height: size(58)
      padding-left: size(16)
      //font-size: 1rem
      font-size: size(16)
      border-radius: size(28)
      white-space: nowrap

    &__list
      &--dropdown,&[aria-expanded]
        .choices__item
          font-size: size(16)
          padding: size(10) size(50) size(10) size(16)
          white-space: nowrap