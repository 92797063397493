.wedo-strategies
  padding-top: 3em
  padding-bottom: 3.7em
  background: rgba(#E6E6EA, .5)

  &__title
    margin-bottom: 2.4em

  &__image
    margin-bottom: 5.2em

  &__description
    line-height: 150%

  @include mq($from: tablet)
    padding-top: 6em
    padding-bottom: 7.2em

    &__container
      display: flex
      align-items: center

    &__image
      flex: size(620) 0 0
      margin-bottom: unset

      .img--bordered
        display: block
        img
          width: 100%

    &__content
      flex: size(594) 0 0
      margin-left: auto
      padding-top: 2.6em

    &__title
      margin-bottom: size(40)

    &__description
      width: size(510)