@font-face
  font-family: 'Poppins'
  src: local("Poppins Regular"), local("Poppins-Regular"), url('../fonts/Poppins-Regular.woff2') format("woff2"), url('../fonts/Poppins-Regular.woff') format("woff")
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Poppins'
  src: local("Poppins Bold"), local("Poppins-Bold"), url('../fonts/Poppins-Bold.woff2') format("woff2"), url('../fonts/Poppins-Bold.woff') format("woff")
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Poppins'
  src: local("Poppins Medium"), local("Poppins-Medium"), url('../fonts/Poppins-Medium.woff2') format("woff2"), url('../fonts/Poppins-Medium.woff') format("woff")
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Poppins'
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url('../fonts/Poppins-SemiBold.woff2') format("woff2"), url('../fonts/Poppins-SemiBold.woff') format("woff")
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Calibri'
  src: local("Calibri"), url('../fonts/Calibri.woff2') format("woff2"), url('../fonts/Calibri.woff') format("woff")
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Calibri'
  src: local("Calibri Bold"), local("Calibri-Bold"), url('../fonts/Calibri-Bold.woff2') format("woff2"), url('../fonts/Calibri-Bold.woff') format("woff")
  font-weight: bold
  font-style: normal
  font-display: swap