// Uncomment lines starting with the "@tailwind" if you are using Tailwind CSS
@tailwind base
@tailwind components
@tailwind utilities

@import "./base/layout"
@import "./blocks/header"
@import "./blocks/main"
@import "./blocks/footer"
@import "./elements/elements"

@import "./blocks/index-hero"
@import "./blocks/index-insights"
@import "./blocks/index-challenge"
@import "./blocks/index-capabilities"
@import "./blocks/index-impact"
@import "./blocks/index-solutions"
@import "./blocks/insights"
@import "./blocks/index-certifications"
@import "./blocks/lets-work"

@import "./blocks/wedo-services"
@import "./blocks/wedo-strategies"

@import "./blocks/service-hero"
@import "./blocks/service-info"
@import "./blocks/service-solutions"
@import "./blocks/service-benefits"
@import "./blocks/service-complimentary"

@import "./blocks/markets-services"

@import "./blocks/innovations-playground"
@import "./blocks/innovations-instruments"

@import "./blocks/insights-updates"

@import "./blocks/contract-list"

@import "./blocks/about-info"
@import "./blocks/about-leadership"
@import "./blocks/about-awards"
@import "./blocks/search-results"

@import "./blocks/contact-form"
@import "./blocks/contact-employment"

@import "./blocks/partners-why"
@import "./blocks/partners-software"
@import "./blocks/partners-ventures"
@import "./blocks/partners-join"

@import "./blocks/careers-life"
@import "./blocks/careers-easp"
@import "./blocks/careers-giving"
@import "./blocks/careers-desk"
@import "./blocks/careers-opportunities"
@import "./blocks/careers-benefits"
@import "./blocks/careers-place"
@import "./blocks/article"