.about-awards
  padding-bottom: 3em

  &__title
    margin-bottom: 2em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__list
    img
      display: block
      max-width: size(170, m)
      max-height: size(170, m)

    .splide__slide
      display: flex
      align-items: center
      justify-content: center
      height: size(200, m)
      width: size(200, m)
      
  @include mq($from: tablet)
    padding-bottom: 6em

    &__title
      margin-bottom: 4em

    &__list
      .splide__slide
        height: size(200)
        width: size(200)
        margin-right: size(30)