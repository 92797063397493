.partners-join
  padding-top: 7.2em
  padding-bottom: 5.7em

  &__title
    margin-bottom: .8em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__description
    font-size: size(20, m)
    line-height: 150%
    text-align: center
    margin-bottom: 2.5em

  @include mq($from: tablet)
    padding-top: 6.8em
    padding-bottom: 4.9em

    &__description
      font-size: size(20)
      width: size(768)
      margin-left: auto
      margin-right: auto
      margin-bottom: 1.9em

    &__title
      margin-bottom: 1.6em

    &__form
      .form
        width: size(768)
        margin-left: auto
        margin-right: auto
        display: flex
        flex-wrap: wrap
        justify-content: space-between

        textarea.input
          height: size(120)

      .form
        &__row
          margin-bottom: 1.1em

          &:not(.form__row--full)
            flex: size(372) 0 0

          &--full
            flex: 100% 0 0

        &__label
          margin-bottom: .5em
          
        .input
          height: size(58)
          
        .choices__inner
          height: size(58)