.footer
  background: $lh-action
  padding-top: 3.35em
  padding-bottom: 2em
  color: #FFF
  position: relative

  .top-scroll
    cursor: pointer

  .logo
    width: size(254, m)

  &__logo
    margin-bottom: 3.55em

  &__contacts
    margin-bottom: 3.6em

    &-item
      display: flex
      align-items: center
      width: size(240, m)
      line-height: 1
      margin-bottom: 2.5em

    &-icon
      margin-right: .5em
      flex: size(30,m) 0 0
      margin-left: size(-5, m)
      display: flex
      justify-content: center

      .icon
        fill: currentColor
        height: auto
        width: size(32, m)

  a
    color: #FFF
    
    &:hover
      text-decoration: underline
      color: #FFF

  &__menu
    font-family: Poppins, sans-serif
    font-weight: 500
    margin-bottom: 3.4em

    li
      margin-bottom: .5em

  &__socials
    margin-bottom: 3.3em

  @include mq($until: tablet)
    &__bottom
      display: flex
      justify-content: space-between
      flex-wrap: wrap

    &__policy
      order: 1

    &__top
      order: 2

    &__copy
      order: 3
      flex: 100% 0 0
      border-top: 1px solid #8B939B
      padding-top: 2.55em
      padding-bottom: 2em
      margin-top: .7em
      text-align: center

  @include mq($from: tablet)
    padding-top: 4.05em

    &__container
      padding-left: size(60)
      padding-right: size(60)
      display: flex
      flex-wrap: wrap
      justify-content: space-between

    &__logo
      order: 1
      margin-bottom: unset

      .logo
        width: size(254)

    &__contacts
      order: 3
      font-size: size(16)
      display: flex
      margin: 1.7em 0 0
      align-self: flex-start

      &-item
        width: 13.4rem
        margin-bottom: unset

      &-icon
        flex-basis: 1.8rem

        .icon
          width: size(32)

    &__menu
      order: 2
      flex: 70% 0 0
      margin-left: auto
      font-size: size(16)
      align-self: center
      margin-bottom: unset
      padding-top: .35em

      &-list
        display: flex
        justify-content: flex-end

        li
          margin-left: 1.05em

    &__socials
      order: 4
      margin-bottom: unset
      align-self: center
      margin-top: 1.3em

    &__bottom
      order: 5
      flex: 100% 0 0
      border-top: 1px solid #8B939B
      display: flex
      justify-content: space-between
      font-size: size(16)
      margin-top: 2.4em
      padding-top: 1.3em