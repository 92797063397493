.about-info
  padding-top: 4.4em
  padding-bottom: 4em

  &__company
    margin-bottom: 4em

    &-image
      margin-bottom: 3.9em

    &-title
      margin-bottom: 2.3em

  &__mission
    margin-bottom: 8em

    &-title
      margin-bottom: 2em

    &-scene
      position: relative
      width: size(213, m)
      display: block
      margin: 5em auto

      &:after
        content: ''
        display: block
        padding-bottom: 100%
        left: 0
        top: 0
        position: unset

    &-image
      position: absolute

      &:nth-child(1)
        width: size(167, m)
        right: 50%
        bottom: 50%

      &:nth-child(2)
        width: size(167, m)
        left: 50%
        top: 50%

  &__culture
    margin-bottom: 4em

    &-title
      margin-bottom: 2em

    &-scene
      position: relative
      width: size(317, m)
      display: block
      margin: 5em 0 5em size(40)

      &:after
        content: ''
        display: block
        left: 0
        top: 0
        position: unset
        padding-bottom: 106.309%


    &-image
      position: absolute
      border-radius: size(8, m)
      width: size(167, m)
      height: size(152, m)
      overflow: hidden

      img
        width: 100%
        height: 100%
        object-fit: cover
        position: absolute

      &:nth-child(1)
        left: size(125, m)
        top: size(-65, m)

      &:nth-child(2)
        left: size(-40, m)
        top: 28%

      &:nth-child(3)
        left: size(125, m)
        bottom: size(-65, m)

  @include mq($from: tablet)
    padding-top: 6.5em
    padding-bottom: 3.5em

    &__company
      display: flex
      margin-bottom: -.3em

      &-image
        flex: size(580) 0 0
        margin-bottom: unset

        .img--bordered
          width: 100%
          display: block

          img
            display: block
            width: 100%

      &-content
        flex: size(580) 0 0
        margin-left: auto
        margin-top: 5.2em

      &-title
        margin-bottom: 1.6em

    &__mission
      display: flex
      margin-bottom: 6.7em

      &-scene
        margin-left: auto
        margin-right: 7em
        margin-top: 9.5em
        width: size(300)

        &:after
          width: size(300)
          height: size(300)

      &-content
        flex: size(588) 0 0
        margin-left: size(40)
        //font-size: size(20)

      &-title
        margin-bottom: 2.5em

      &-image
        &:nth-child(n)
          width: size(230)
          height: size(230)

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: absolute

    &__culture
      display: flex
      flex-direction: row-reverse

      &-content
        flex: size(580) 0 0
        margin-left: auto
        margin-right: size(20)

      &-title
        margin-bottom: 2.4em

      &-scene
        flex: size(460) 0 0
        margin: -7.8em 0 5em 4rem
        align-self: center

        &:after
          padding-bottom: 100%

      &-image
        width: size(230)
        height: size(230)
        left: size(150)

        &:nth-child(1)
          top: size(-127)

        &:nth-child(2)
          left: size(-80)
          top: 22%

        &:nth-child(3)
          bottom: size(-103)
          left: size(150)



