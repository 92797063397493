.innovations-instruments
  padding-top: 1em
  padding-bottom: 1em
  
  &__item
    $item: &

    background: $lh-accent
    border-radius: size(8, m)
    min-height: size(238, m)
    position: relative
    margin-bottom: size(57, m)

    &-logo
      position: absolute
      display: flex
      //align-items: center
      justify-content: center
      top: 0
      right: 0
      bottom: 0
      left: 0
      opacity: 1
      padding: size(40, m) size(40, m)

      svg
        fill: #fff
        max-width: size(300, m)
        max-height: size(200, m)
        width: fit-content

      img
        max-height: size(200, m)
        width: fit-content

    &-toggler
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      background: $lh-green
      color: #FFF
      cursor: pointer
      position: absolute
      left: 50%
      top: 100%
      transform: translateX(-50%) translateY(-50%)
      width: size(56)
      height: size(56)

      &:after, &:before
        content: ''
        display: block
        height: size(4, m)
        width: size(18, m)
        background: currentColor
        position: absolute

      &:after
        transform: rotate(-90deg)

    &-buttons
      display: flex
      justify-content: space-between
      align-items: center
      margin-top: 1em
      padding-bottom: 1em

    &-description
      color: #FFF
      font-size: size(20, m)
      line-height: 150%
      padding: size(20, m) size(16, m)

    &.is-open
      #{$item}
        &-logo
          opacity: 0

        &-toggler
          &:after
            opacity: 0

  @include mq($from: tablet)
    padding-bottom: unset

    &__items
      display: flex
      justify-content: space-between
      align-items: flex-start

    &__item
      flex: size(408) 0 0
      min-height: 17.275rem

      &-toggler
        &:before, &:after
          height: size(4)
          width: size(22)


      &-logo
        svg
          max-width: size(300)
          max-height: size(200)

      &-button
        padding-top: size(20)
        padding-bottom: size(40)
        
      &-description
        padding: size(40) size(40) size(48)
        font-size: size(20)