h1,.h1, h2,.h2, h3,.h3, h4,.h4, h5,.h5, h6,.h6
  font-weight: 600
  font-family: "Poppins", sans-serif
  line-height: 120%
  margin: unset
  text-transform: capitalize

h1,.h1
  font-size: size(48, m)

  @include mq($from: tablet)
    font-size: size(100)

h2,.h2
  font-size: size(48, m)
  line-height: 120%

  @include mq($from: tablet)
    font-size: size(80)

h3,.h3
  font-size: size(32, m)

  @include mq($from: tablet)
    font-size: size(64)

h4,.h4
  font-size: size(24, m)

  @include mq($from: tablet)
    font-size: size(24)

h5,.h5
  font-size: size(18, m)

  @include mq($from: tablet)
    font-size: size(20)