.popup
  .fancybox__slide
    padding: unset

  .fancybox__backdrop
    background: rgba(217, 217, 217, 0.80)
    backdrop-filter: blur(5px)

  .fancybox__content
    position: relative
    display: flex
    padding: 5.5em 0 0

    > .container
      overflow: auto
      max-height: 100%
      flex: 1
      width: 100%

  &__close
    background: #063A59
    width: size(48, m)
    height: size(48, m)
    border-radius: 50%
    display: flex !important
    align-items: center
    justify-content: center
    cursor: pointer
    position: absolute
    top: 1em
    left: 50%
    transform: translateX(-50%)

    &:after
      content: ''
      display: block
      background: #FFF
      mask: url("../images/sprites/main.stack.svg#image-close") 50% 50% no-repeat
      mask-size: contain
      width: size(24, m)
      height: size(24, m)

  @include mq($until: tablet)
    &-video
      .fancybox__content
        padding-top: unset

      .popup__close
        top: unset
        bottom: 100%
        transform: translate(-50%, 50%)

  @include mq($from: tablet)
    left: 20vw
    right: unset
    margin-left: auto
    margin-right: auto
    width: 60vw
    padding-top: size(40)
    padding-bottom: size(40)

    .fancybox__slide
      overflow: unset

    .fancybox__carousel
      overflow: unset

    .fancybox__content
      padding-top: 0
      padding-bottom: 0

    .has-youtube
      .fancybox__content
        width: 75vw!important
        height: 50vw !important
        padding-top: unset

    &.fancybox__container:not(.is-compact)
      .fancybox__slide.has-close-btn
        padding-top: unset

    &__close
      width: size(48)
      height: size(48)
      left: unset
      right: -1em
      top: -1em
      transform: unset
      
      &:after
        width: size(24)
        height: size(24)

.popup-bio
  &__image
    width: size(192, m)
    height: size(192, m)
    border-radius: size(8, m)
    overflow: hidden
    margin-left: auto
    margin-right: auto
    margin-bottom: size(63, m)

    img
      object-fit: cover
      width: 100%
      height: 100%

  &__name
    text-align: center
    margin-bottom: size(30, m)

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__position
    color: #014E6B
    font-size: size(20, m)
    line-height: 150%
    font-family: Poppins, sans-serif
    text-align: center
    margin-bottom: size(19, m)
    font-weight: 500

  .socials
    margin-bottom: size(27, m)
    justify-content: center

    li
      margin-left: 1em

  @include mq($from: tablet)
    border: 2px solid #063A59
    border-radius: size(8)
    padding-top: 2.5em !important

    .container
      padding-left: size(60)
      padding-right: size(60)

    &__header
      display: flex
      margin-bottom: size(40)

    &__thumb
      flex: size(252) 0 0
      margin-right: size(85)

    &__image
      width: 100%
      height: unset
      margin-bottom: unset

    &__info
      flex: 1
      align-self: flex-end

    &__name
      text-align: unset
      margin-bottom: size(20)

      .h3
        font-size: size(40)

        &:after
          margin-left: unset

    &__position
      text-align: unset
      font-size: size(20)
      width: size(328)
      margin-bottom: .5em

    .socials
      justify-content: unset
      margin-bottom: unset

      li
        margin-left: unset

      .icon
        width: size(40)
        fill: $lh-action

    &__description
      font-size: size(16)