.about-leadership
  padding-top: 3em
  padding-bottom: 3em

  &__title
    margin-bottom: 2em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__list
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  &__item
    flex: size(160, m) 0 0
    margin-bottom: size(56, m)
    cursor: pointer

    &-image
      position: relative
      border-radius: size(8, m)
      overflow: hidden
      margin-bottom: size(24, m)
      filter: grayscale(1)
      transition: .3s ease

      img
        width: 100%
        height: 100%
        object-fit: cover
        position: absolute

      &:after
        content: ''
        display: block
        padding-bottom: 100%

    &:hover
      .about-leadership__item-image
        filter: grayscale(0)

    &-info
      text-align: center

    &-name
      font-size: size(20, m)
      font-weight: 500
      font-family: Poppins, sans-serif
      line-height: 150%

    &-position
      line-height: 150%

  @include mq($from: tablet)
    padding-top: 7.4em

    &__title
      margin-bottom: 4em

      &--directors
        margin-bottom: 3.7em

        .h3
          font-size: size(40)

    &__item
      width: size(304)
      flex-basis: size(304)
      margin-right: size(32)
      margin-bottom: 2.1rem

      &-image
        margin-bottom: 1.3em

      &-name
        font-size: size(20)
        margin-bottom: .2em

      &-position
        font-size: size(16)

    &__list
      margin-left: size(-16)
      margin-right: size(-64)
      justify-content: unset
      margin-bottom: 5.2em