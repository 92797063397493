.stats
  font-family: Poppins, sans-serif
  font-weight: 500

  &__item
    display: flex
    align-items: center
    margin-bottom: 2.5em

    &--block
      display: block

    &-value
      font-size: size(64, m)
      font-weight: bold
      color: $lh-green
      line-height: 1
      margin-right: .5em
      flex: 42% 0 0

    &-label
      font-size: size(20, m)
      line-height: 150%

  @include mq($from: tablet)
    &__item
      &-value
        font-size: size(80)