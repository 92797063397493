.innovations-playground
  padding-top: 6em
  padding-bottom: 1em

  &__logo
    display: flex
    justify-content: center
    margin-bottom: 3.7em

    img
      max-width: 62.6%

  &__intro
    font-size: size(20, m)
    font-family: Poppins, sans-serif
    font-weight: 500
    line-height: 150%
    margin-bottom: 2.4em
    color: #231F20
    align-self: center

  &__info
    margin-bottom: 5.7em

    &-image
      margin-bottom: 5.5em

    &-title
      margin-bottom: 1.2em

  &__process
    &-title
      margin-bottom: 2.9em

  &__steps
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    margin-bottom: 2em

    &-item
      &:not(:last-child)
        margin-bottom: -1em

      &:nth-child(even)
        margin-left: size(48, m)

      img
        width: size(300, m)

        &:nth-child(2)
          display: none

  @include mq($from: tablet)
    padding-bottom: 4.6em

    &__container
      display: flex
      flex-wrap: wrap

    &__intro
      flex: size(777) 0 0
      margin-left: auto
      font-size: size(24)
      margin-bottom: unset
      padding-bottom: 1.4em

    &__logo
      flex: size(340) 0 0
      display: flex
      justify-content: center
      margin-right: auto
      margin-bottom: unset

      img
        max-width: 100%

    &__info
      display: flex
      flex-direction: row-reverse
      width: 100%
      margin-top: 6em
      margin-bottom: .4em

      &-title
        margin-bottom: 1.9em

      &-image
        flex: size(619) 0 0
        margin-left: auto

        .img--bordered
          width: 100%

          img
            display: block
            width: 100%

      &-content
        flex: size(510) 0 0
        padding-top: .3em

      &-description
        font-size: size(20)

    &__process
      width: 100%

      &-title
        margin-bottom: 4em
        width: size(600)

    &__steps
      max-width: 55.7em
      margin-left: auto
      margin-right: auto
      flex-direction: row

      &-item
        width: size(301)
        opacity: 0
        transition: .5s ease

        &:nth-child(even)
          margin-left: unset

        &.is-active
          opacity: 1

        img
          width: 100%

          &:nth-child(1)
            display: none

          &:nth-child(2)
            display: block

        &:not(:last-child)
          margin-right: -2em

