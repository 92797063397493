.contact-form
  padding-top: 4.7em
  padding-bottom: 3.4em

  &__title
    margin-bottom: 6em

  @include mq($from: tablet)
    padding-top: 4.4em
    padding-bottom: 4em

    &__title
      margin-bottom: 2.1em

    &__form
      width: size(595)