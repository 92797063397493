.careers-place
  padding-top: 5em
  padding-bottom: 5em

  &__title
    margin-bottom: 1.4em
    padding-right: 1.4em

  &__description
    line-height: 150%

  &__image
    margin-bottom: 2em
    display: flex
    justify-content: center

    img
      max-width: size(175, m)

  &__container
    display: flex
    flex-direction: column-reverse

  @include mq($from: tablet)
    padding-top: 6em
    padding-bottom: 6em

    &__container
      flex-direction: row-reverse

    &__image
      flex: size(626) 0 0
      padding-left: 1.7em

      img
        max-width: 100%
        display: block
        margin-left: auto
        margin-right: auto

    &__content
      padding-top: 1.2em

    &__title
      margin-bottom: 2.1em

    &__description
      width: size(510)