.swiper
  &-button
    opacity: unset
    width: size(48, m)
    height: size(48, m)
    border-radius: 50%
    background: #FFF
    display: flex
    align-items: center
    justify-content: center
    margin-top: unset
    top: 3.5em

    .icon
      height: auto
      width: size(17, m)
      fill: #014E6B

    &-next
      padding-left: .2em

    &-prev
      padding-right: .2em

    &:after
      display: none


  &-buttons
    display: flex
    justify-content: space-between

  &-pagination
    &-bullet
      width: size(9, m)
      height: size(9, m)
      background: none
      border: 1px solid $lh-green
      opacity: 1
      margin: size(8, m) !important

      &-active
        background: $lh-green

  @include mq($from: tablet)
    &-button
      width: size(56)
      height: size(56)

      .icon
        width: size(17)

    &-pagination
      left: size(21) !important
      bottom: 0 !important

      &-bullet
        width: size(12)
        height: size(12)
        margin: size(6) !important