.index-certifications
  padding-top: 4.7em
  padding-bottom: 3.3em

  &__title
    text-align: center
    margin-bottom: 4.2em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__list
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    margin-bottom: 3em

    &-item
      flex: size(130, m) 0 0

      &:not(:last-child)
        margin-bottom: 6.5em

  &__cmmi
    &-item
      width: size(300, m)
      margin-left: auto
      margin-right: auto
      margin-bottom: size(48, m)

  @include mq($from: tablet)
    padding-top: 6.3em
    padding-bottom: 3em

    &__container
      padding-left: size(280)
      padding-right: size(280)

    &__list
      justify-content: space-between
      margin-bottom: 3.1rem

      &-item
        &:nth-child(n)
          margin-bottom: unset

    &__title
      margin-bottom: 4.9em

    &__cmmi
      display: flex

      &-item
        width: size(395)