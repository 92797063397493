.wedo-services
  padding-top: 6.9em
  padding-bottom: 1.7em

  &__title
    margin-bottom: 1.2em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  @include mq($from: tablet)
    padding-top: 6.3em
    padding-bottom: 5em

    &__title
      margin-bottom: 4em

    &__container
      padding-left: size(160)
      padding-right: size(160)