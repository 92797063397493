.service-info
  padding-top: 4.6rem
  padding-bottom: 2.8rem

  .h4
    line-height: 120%

  &__title
    margin-bottom: 2.4em

  &__image
    margin-top: 2.5em
    margin-bottom: 3.3em

    &--desktop
      display: none

  @include mq($from: tablet)
    padding-top: 6rem
    padding-bottom: 5.3rem

    &__container
      display: flex
      align-items: center

    &__content
      flex: size(550) 0 0
      margin-left: auto
      padding-top: 0.3em

    .h4
      font-size: size(40)

    &__image
      flex: size(620) 0 0
      margin-top: unset
      margin-bottom: unset

      .img--bordered
        width: size(580)
      
      img
        display: block
        width: 100%
        

      &--desktop
        display: block

      &--mobile
        display: none

    &__description
      padding-right: 1em

    &__title
      margin-bottom: 1.9em