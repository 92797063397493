@use 'sass:math';

%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-angle-down {
	@extend %svg-common;
	background-position: 99.75490196078431% 92.56756756756756%;
	background-size: math.div(834, 18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-angle-down {
	background-position: 99.75490196078431% 92.56756756756756%;
}
@mixin svg-image-angle-down-hover {
	.svg-image-angle-down {
		@include svg-pos-image-angle-down-hover
	}
}

.svg-image-angle-down:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(18, 18)*100%;
}

.svg-image-angle-down-dims {
	width: 18px;
	height: 18px;
}

.svg-image-angle-right {
	@extend %svg-common;
	background-position: 86.40776699029126% 98.99328859060402%;
	background-size: math.div(834, 10)*100% auto;
	width: 10px;

}
@mixin svg-pos-image-angle-right {
	background-position: 86.40776699029126% 98.99328859060402%;
}
@mixin svg-image-angle-right-hover {
	.svg-image-angle-right {
		@include svg-pos-image-angle-right-hover
	}
}

.svg-image-angle-right:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(14, 10)*100%;
}

.svg-image-angle-right-dims {
	width: 10px;
	height: 14px;
}

.svg-image-arrow-right {
	@extend %svg-common;
	background-position: 75.58859975216852% 95.86206896551724%;
	background-size: math.div(834, 27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-arrow-right {
	background-position: 75.58859975216852% 95.86206896551724%;
}
@mixin svg-image-arrow-right-hover {
	.svg-image-arrow-right {
		@include svg-pos-image-arrow-right-hover
	}
}

.svg-image-arrow-right:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(30, 27)*100%;
}

.svg-image-arrow-right-dims {
	width: 27px;
	height: 30px;
}

.svg-image-benefit-cost {
	@extend %svg-common;
	background-position: 97.26775956284153% 96.06299212598425%;
	background-size: math.div(834, 102)*100% auto;
	width: 102px;

}
@mixin svg-pos-image-benefit-cost {
	background-position: 97.26775956284153% 96.06299212598425%;
}
@mixin svg-image-benefit-cost-hover {
	.svg-image-benefit-cost {
		@include svg-pos-image-benefit-cost-hover
	}
}

.svg-image-benefit-cost:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(102, 102)*100%;
}

.svg-image-benefit-cost-dims {
	width: 102px;
	height: 102px;
}

.svg-image-benefit-quality {
	@extend %svg-common;
	background-position: 32.923497267759565% 24.015748031496063%;
	background-size: math.div(834, 102)*100% auto;
	width: 102px;

}
@mixin svg-pos-image-benefit-quality {
	background-position: 32.923497267759565% 24.015748031496063%;
}
@mixin svg-image-benefit-quality-hover {
	.svg-image-benefit-quality {
		@include svg-pos-image-benefit-quality-hover
	}
}

.svg-image-benefit-quality:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(102, 102)*100%;
}

.svg-image-benefit-quality-dims {
	width: 102px;
	height: 102px;
}

.svg-image-benefit-speed {
	@extend %svg-common;
	background-position: 50% 48.031496062992126%;
	background-size: math.div(834, 102)*100% auto;
	width: 102px;

}
@mixin svg-pos-image-benefit-speed {
	background-position: 50% 48.031496062992126%;
}
@mixin svg-image-benefit-speed-hover {
	.svg-image-benefit-speed {
		@include svg-pos-image-benefit-speed-hover
	}
}

.svg-image-benefit-speed:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(102, 102)*100%;
}

.svg-image-benefit-speed-dims {
	width: 102px;
	height: 102px;
}

.svg-image-close {
	@extend %svg-common;
	background-position: 78.93432465923172% 95.36878216123499%;
	background-size: math.div(834, 27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-close {
	background-position: 78.93432465923172% 95.36878216123499%;
}
@mixin svg-image-close-hover {
	.svg-image-close {
		@include svg-pos-image-close-hover
	}
}

.svg-image-close:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(27, 27)*100%;
}

.svg-image-close-dims {
	width: 27px;
	height: 27px;
}

.svg-image-download {
	@extend %svg-common;
	background-position: 60.69651741293532% 78.78260869565217%;
	background-size: math.div(834, 30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-download {
	background-position: 60.69651741293532% 78.78260869565217%;
}
@mixin svg-image-download-hover {
	.svg-image-download {
		@include svg-pos-image-download-hover
	}
}

.svg-image-download:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(35, 30)*100%;
}

.svg-image-download-dims {
	width: 30px;
	height: 35px;
}

.svg-image-facebook {
	@extend %svg-common;
	background-position: 69.125% 78.64583333333333%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-facebook {
	background-position: 69.125% 78.64583333333333%;
}
@mixin svg-image-facebook-hover {
	.svg-image-facebook {
		@include svg-pos-image-facebook-hover
	}
}

.svg-image-facebook:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-facebook-dims {
	width: 34px;
	height: 34px;
}

.svg-image-icon-analytics {
	@extend %svg-common;
	background-position: 0 0;
	background-size: math.div(834, 102)*100% auto;
	width: 102px;

}
@mixin svg-pos-image-icon-analytics {
	background-position: 0 0;
}
@mixin svg-image-icon-analytics-hover {
	.svg-image-icon-analytics {
		@include svg-pos-image-icon-analytics-hover
	}
}

.svg-image-icon-analytics:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 102)*100%;
}

.svg-image-icon-analytics-dims {
	width: 102px;
	height: 122px;
}

.svg-image-icon-application {
	@extend %svg-common;
	background-position: 14.325842696629213% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-icon-application {
	background-position: 14.325842696629213% 0;
}
@mixin svg-image-icon-application-hover {
	.svg-image-icon-application {
		@include svg-pos-image-icon-application-hover
	}
}

.svg-image-icon-application:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-icon-application-dims {
	width: 122px;
	height: 122px;
}

.svg-image-icon-cloud {
	@extend %svg-common;
	background-position: 31.46067415730337% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-icon-cloud {
	background-position: 31.46067415730337% 0;
}
@mixin svg-image-icon-cloud-hover {
	.svg-image-icon-cloud {
		@include svg-pos-image-icon-cloud-hover
	}
}

.svg-image-icon-cloud:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-icon-cloud-dims {
	width: 122px;
	height: 122px;
}

.svg-image-icon-it {
	@extend %svg-common;
	background-position: 0 25%;
	background-size: math.div(834, 119)*100% auto;
	width: 119px;

}
@mixin svg-pos-image-icon-it {
	background-position: 0 25%;
}
@mixin svg-image-icon-it-hover {
	.svg-image-icon-it {
		@include svg-pos-image-icon-it-hover
	}
}

.svg-image-icon-it:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 119)*100%;
}

.svg-image-icon-it-dims {
	width: 119px;
	height: 122px;
}

.svg-image-icon-security {
	@extend %svg-common;
	background-position: 16.713483146067414% 25%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-icon-security {
	background-position: 16.713483146067414% 25%;
}
@mixin svg-image-icon-security-hover {
	.svg-image-icon-security {
		@include svg-pos-image-icon-security-hover
	}
}

.svg-image-icon-security:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-icon-security-dims {
	width: 122px;
	height: 122px;
}

.svg-image-icon-service {
	@extend %svg-common;
	background-position: 48.59550561797753% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-icon-service {
	background-position: 48.59550561797753% 0;
}
@mixin svg-image-icon-service-hover {
	.svg-image-icon-service {
		@include svg-pos-image-icon-service-hover
	}
}

.svg-image-icon-service:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-icon-service-dims {
	width: 122px;
	height: 122px;
}

.svg-image-instagram {
	@extend %svg-common;
	background-position: 76.25% 84.72222222222223%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-instagram {
	background-position: 76.25% 84.72222222222223%;
}
@mixin svg-image-instagram-hover {
	.svg-image-instagram {
		@include svg-pos-image-instagram-hover
	}
}

.svg-image-instagram:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-instagram-dims {
	width: 34px;
	height: 34px;
}

.svg-image-link {
	@extend %svg-common;
	background-position: 64.83103879849813% 78.78260869565217%;
	background-size: math.div(834, 35)*100% auto;
	width: 35px;

}
@mixin svg-pos-image-link {
	background-position: 64.83103879849813% 78.78260869565217%;
}
@mixin svg-image-link-hover {
	.svg-image-link {
		@include svg-pos-image-link-hover
	}
}

.svg-image-link:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(35, 35)*100%;
}

.svg-image-link-dims {
	width: 35px;
	height: 35px;
}

.svg-image-linkedin-in {
	@extend %svg-common;
	background-position: 80.5% 84.72222222222223%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-linkedin-in {
	background-position: 80.5% 84.72222222222223%;
}
@mixin svg-image-linkedin-in-hover {
	.svg-image-linkedin-in {
		@include svg-pos-image-linkedin-in-hover
	}
}

.svg-image-linkedin-in:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-linkedin-in-dims {
	width: 34px;
	height: 34px;
}

.svg-image-linkedin-squared {
	@extend %svg-common;
	background-position: 84.53865336658355% 90.31141868512111%;
	background-size: math.div(834, 32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-linkedin-squared {
	background-position: 84.53865336658355% 90.31141868512111%;
}
@mixin svg-image-linkedin-squared-hover {
	.svg-image-linkedin-squared {
		@include svg-pos-image-linkedin-squared-hover
	}
}

.svg-image-linkedin-squared:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(32, 32)*100%;
}

.svg-image-linkedin-squared-dims {
	width: 32px;
	height: 32px;
}

.svg-image-magnifier {
	@extend %svg-common;
	background-position: 99.75490196078431% 95.60810810810811%;
	background-size: math.div(834, 18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-magnifier {
	background-position: 99.75490196078431% 95.60810810810811%;
}
@mixin svg-image-magnifier-hover {
	.svg-image-magnifier {
		@include svg-pos-image-magnifier-hover
	}
}

.svg-image-magnifier:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(18, 18)*100%;
}

.svg-image-magnifier-dims {
	width: 18px;
	height: 18px;
}

.svg-image-map {
	@extend %svg-common;
	background-position: 84.75% 84.72222222222223%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-map {
	background-position: 84.75% 84.72222222222223%;
}
@mixin svg-image-map-hover {
	.svg-image-map {
		@include svg-pos-image-map-hover
	}
}

.svg-image-map:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-map-dims {
	width: 34px;
	height: 34px;
}

.svg-image-next {
	@extend %svg-common;
	background-position: 99.87730061349693% 84.13793103448276%;
	background-size: math.div(834, 19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-next {
	background-position: 99.87730061349693% 84.13793103448276%;
}
@mixin svg-image-next-hover {
	.svg-image-next {
		@include svg-pos-image-next-hover
	}
}

.svg-image-next:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(30, 19)*100%;
}

.svg-image-next-dims {
	width: 19px;
	height: 30px;
}

.svg-image-phone {
	@extend %svg-common;
	background-position: 76.25% 90.625%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-phone {
	background-position: 76.25% 90.625%;
}
@mixin svg-image-phone-hover {
	.svg-image-phone {
		@include svg-pos-image-phone-hover
	}
}

.svg-image-phone:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-phone-dims {
	width: 34px;
	height: 34px;
}

.svg-image-play {
	@extend %svg-common;
	background-position: 65.32797858099063% 69.98087954110899%;
	background-size: math.div(834, 87)*100% auto;
	width: 87px;

}
@mixin svg-pos-image-play {
	background-position: 65.32797858099063% 69.98087954110899%;
}
@mixin svg-image-play-hover {
	.svg-image-play {
		@include svg-pos-image-play-hover
	}
}

.svg-image-play:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(87, 87)*100%;
}

.svg-image-play-dims {
	width: 87px;
	height: 87px;
}

.svg-image-prev {
	@extend %svg-common;
	background-position: 99.87730061349693% 89.3103448275862%;
	background-size: math.div(834, 19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-prev {
	background-position: 99.87730061349693% 89.3103448275862%;
}
@mixin svg-image-prev-hover {
	.svg-image-prev {
		@include svg-pos-image-prev-hover
	}
}

.svg-image-prev:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(30, 19)*100%;
}

.svg-image-prev-dims {
	width: 19px;
	height: 30px;
}

.svg-image-service-application-services {
	@extend %svg-common;
	background-position: 48.59550561797753% 25%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-application-services {
	background-position: 48.59550561797753% 25%;
}
@mixin svg-image-service-application-services-hover {
	.svg-image-service-application-services {
		@include svg-pos-image-service-application-services-hover
	}
}

.svg-image-service-application-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-application-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-asset-management {
	@extend %svg-common;
	background-position: 0 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-asset-management {
	background-position: 0 50%;
}
@mixin svg-image-service-asset-management-hover {
	.svg-image-service-asset-management {
		@include svg-pos-image-service-asset-management-hover
	}
}

.svg-image-service-asset-management:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-asset-management-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-automation-services {
	@extend %svg-common;
	background-position: 17.134831460674157% 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-automation-services {
	background-position: 17.134831460674157% 50%;
}
@mixin svg-image-service-automation-services-hover {
	.svg-image-service-automation-services {
		@include svg-pos-image-service-automation-services-hover
	}
}

.svg-image-service-automation-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-automation-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-business-mission-operations {
	@extend %svg-common;
	background-position: 34.26966292134831% 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-business-mission-operations {
	background-position: 34.26966292134831% 50%;
}
@mixin svg-image-service-business-mission-operations-hover {
	.svg-image-service-business-mission-operations {
		@include svg-pos-image-service-business-mission-operations-hover
	}
}

.svg-image-service-business-mission-operations:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-business-mission-operations-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-cloud-security {
	@extend %svg-common;
	background-position: 65.73033707865169% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-cloud-security {
	background-position: 65.73033707865169% 0;
}
@mixin svg-image-service-cloud-security-hover {
	.svg-image-service-cloud-security {
		@include svg-pos-image-service-cloud-security-hover
	}
}

.svg-image-service-cloud-security:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-cloud-security-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-cloud-services {
	@extend %svg-common;
	background-position: 65.73033707865169% 25%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-cloud-services {
	background-position: 65.73033707865169% 25%;
}
@mixin svg-image-service-cloud-services-hover {
	.svg-image-service-cloud-services {
		@include svg-pos-image-service-cloud-services-hover
	}
}

.svg-image-service-cloud-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-cloud-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-cybersecurity {
	@extend %svg-common;
	background-position: 65.73033707865169% 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-cybersecurity {
	background-position: 65.73033707865169% 50%;
}
@mixin svg-image-service-cybersecurity-hover {
	.svg-image-service-cybersecurity {
		@include svg-pos-image-service-cybersecurity-hover
	}
}

.svg-image-service-cybersecurity:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-cybersecurity-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-data-analytics {
	@extend %svg-common;
	background-position: 0 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-data-analytics {
	background-position: 0 75%;
}
@mixin svg-image-service-data-analytics-hover {
	.svg-image-service-data-analytics {
		@include svg-pos-image-service-data-analytics-hover
	}
}

.svg-image-service-data-analytics:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-data-analytics-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-data-governance {
	@extend %svg-common;
	background-position: 17.134831460674157% 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-data-governance {
	background-position: 17.134831460674157% 75%;
}
@mixin svg-image-service-data-governance-hover {
	.svg-image-service-data-governance {
		@include svg-pos-image-service-data-governance-hover
	}
}

.svg-image-service-data-governance:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-data-governance-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-data-operations {
	@extend %svg-common;
	background-position: 34.26966292134831% 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-data-operations {
	background-position: 34.26966292134831% 75%;
}
@mixin svg-image-service-data-operations-hover {
	.svg-image-service-data-operations {
		@include svg-pos-image-service-data-operations-hover
	}
}

.svg-image-service-data-operations:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-data-operations-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-data-security {
	@extend %svg-common;
	background-position: 51.40449438202247% 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-data-security {
	background-position: 51.40449438202247% 75%;
}
@mixin svg-image-service-data-security-hover {
	.svg-image-service-data-security {
		@include svg-pos-image-service-data-security-hover
	}
}

.svg-image-service-data-security:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-data-security-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-decision-intelligence {
	@extend %svg-common;
	background-position: 82.86516853932584% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-decision-intelligence {
	background-position: 82.86516853932584% 0;
}
@mixin svg-image-service-decision-intelligence-hover {
	.svg-image-service-decision-intelligence {
		@include svg-pos-image-service-decision-intelligence-hover
	}
}

.svg-image-service-decision-intelligence:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-decision-intelligence-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-dev-sec-ops {
	@extend %svg-common;
	background-position: 82.86516853932584% 25%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-dev-sec-ops {
	background-position: 82.86516853932584% 25%;
}
@mixin svg-image-service-dev-sec-ops-hover {
	.svg-image-service-dev-sec-ops {
		@include svg-pos-image-service-dev-sec-ops-hover
	}
}

.svg-image-service-dev-sec-ops:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-dev-sec-ops-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-grants-financial-services {
	@extend %svg-common;
	background-position: 82.86516853932584% 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-grants-financial-services {
	background-position: 82.86516853932584% 50%;
}
@mixin svg-image-service-grants-financial-services-hover {
	.svg-image-service-grants-financial-services {
		@include svg-pos-image-service-grants-financial-services-hover
	}
}

.svg-image-service-grants-financial-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-grants-financial-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-help-desk-contact-center-support {
	@extend %svg-common;
	background-position: 82.86516853932584% 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-help-desk-contact-center-support {
	background-position: 82.86516853932584% 75%;
}
@mixin svg-image-service-help-desk-contact-center-support-hover {
	.svg-image-service-help-desk-contact-center-support {
		@include svg-pos-image-service-help-desk-contact-center-support-hover
	}
}

.svg-image-service-help-desk-contact-center-support:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-help-desk-contact-center-support-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-human-centered-design {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-human-centered-design {
	background-position: 0 100%;
}
@mixin svg-image-service-human-centered-design-hover {
	.svg-image-service-human-centered-design {
		@include svg-pos-image-service-human-centered-design-hover
	}
}

.svg-image-service-human-centered-design:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-human-centered-design-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-hybrid-cloud-automation {
	@extend %svg-common;
	background-position: 17.134831460674157% 100%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-hybrid-cloud-automation {
	background-position: 17.134831460674157% 100%;
}
@mixin svg-image-service-hybrid-cloud-automation-hover {
	.svg-image-service-hybrid-cloud-automation {
		@include svg-pos-image-service-hybrid-cloud-automation-hover
	}
}

.svg-image-service-hybrid-cloud-automation:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-hybrid-cloud-automation-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-it-services {
	@extend %svg-common;
	background-position: 34.26966292134831% 100%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-it-services {
	background-position: 34.26966292134831% 100%;
}
@mixin svg-image-service-it-services-hover {
	.svg-image-service-it-services {
		@include svg-pos-image-service-it-services-hover
	}
}

.svg-image-service-it-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-it-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-mission-at-scale {
	@extend %svg-common;
	background-position: 51.40449438202247% 100%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-mission-at-scale {
	background-position: 51.40449438202247% 100%;
}
@mixin svg-image-service-mission-at-scale-hover {
	.svg-image-service-mission-at-scale {
		@include svg-pos-image-service-mission-at-scale-hover
	}
}

.svg-image-service-mission-at-scale:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-mission-at-scale-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-policy-planning {
	@extend %svg-common;
	background-position: 68.53932584269663% 100%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-policy-planning {
	background-position: 68.53932584269663% 100%;
}
@mixin svg-image-service-policy-planning-hover {
	.svg-image-service-policy-planning {
		@include svg-pos-image-service-policy-planning-hover
	}
}

.svg-image-service-policy-planning:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-policy-planning-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-software-factories {
	@extend %svg-common;
	background-position: 100% 0;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-software-factories {
	background-position: 100% 0;
}
@mixin svg-image-service-software-factories-hover {
	.svg-image-service-software-factories {
		@include svg-pos-image-service-software-factories-hover
	}
}

.svg-image-service-software-factories:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-software-factories-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-strategic-communications {
	@extend %svg-common;
	background-position: 100% 25%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-strategic-communications {
	background-position: 100% 25%;
}
@mixin svg-image-service-strategic-communications-hover {
	.svg-image-service-strategic-communications {
		@include svg-pos-image-service-strategic-communications-hover
	}
}

.svg-image-service-strategic-communications:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-strategic-communications-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-web-services {
	@extend %svg-common;
	background-position: 100% 50%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-web-services {
	background-position: 100% 50%;
}
@mixin svg-image-service-web-services-hover {
	.svg-image-service-web-services {
		@include svg-pos-image-service-web-services-hover
	}
}

.svg-image-service-web-services:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-web-services-dims {
	width: 122px;
	height: 122px;
}

.svg-image-service-zero-trust-architecture {
	@extend %svg-common;
	background-position: 100% 75%;
	background-size: math.div(834, 122)*100% auto;
	width: 122px;

}
@mixin svg-pos-image-service-zero-trust-architecture {
	background-position: 100% 75%;
}
@mixin svg-image-service-zero-trust-architecture-hover {
	.svg-image-service-zero-trust-architecture {
		@include svg-pos-image-service-zero-trust-architecture-hover
	}
}

.svg-image-service-zero-trust-architecture:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(122, 122)*100%;
}

.svg-image-service-zero-trust-architecture-dims {
	width: 122px;
	height: 122px;
}

.svg-image-x-twitter {
	@extend %svg-common;
	background-position: 80.5% 90.625%;
	background-size: math.div(834, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-x-twitter {
	background-position: 80.5% 90.625%;
}
@mixin svg-image-x-twitter-hover {
	.svg-image-x-twitter {
		@include svg-pos-image-x-twitter-hover
	}
}

.svg-image-x-twitter:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-x-twitter-dims {
	width: 34px;
	height: 34px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }