.index-hero
  position: relative
  min-height: 100vh
  display: flex
  background: $lh-accent-2

  &__media
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    //z-index: -1

    &:after
      content: ''
      display: block
      position: absolute
      background: linear-gradient(180deg, #063A59 0%, rgba(0, 97, 148, 0) 100%)
      top: 0
      right: 0
      bottom: 0
      left: 0
      opacity: .5

    img, video
      width: 100%
      height: 100%
      object-fit: cover
      
  &__content
    color: #FFF
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-top: 4.2em
    position: relative

  &__container
    display: flex
    width: 100%

  &__title
    margin-bottom: size(91, m)

    .h1
      line-height: 120%

    span
      text-transform: uppercase

  &__buttons
    .btn
      background: rgba(#FFF, .4)
      backdrop-filter: blur(2px)
      color: #FFF

      &:hover
        color: $lh-green
        background: #FFF



  .btn-play
    .icon
      transition: fill .3s ease
      fill: $lh-green
      cursor: pointer
      height: auto
      width: size(85, m)

    &:hover
      fill: $lh-accent

  @include mq($until: tablet)
    .btn-play
      margin-bottom: size(61, m)

    &__buttons
      display: flex
      flex-direction: column
      align-items: center
      width: fit-content
      margin-left: auto
      margin-right: auto

      .btn
        margin-bottom: size(19, m)

      &-items
        display: flex
        flex-direction: column
        align-items: center
        width: fit-content
        margin-left: auto
        margin-right: auto


  @include mq($from: 560px, $until: tablet)
    &__buttons
      width: 100%

      &-items
        display: flex
        flex-direction: row
        width: 100%
        justify-content: center

      .btn
        font-size: size(12, m)
        padding-top: .5em
        padding-bottom: .5em
        height: 3.5em
        margin-left: .5em
        margin-right: .5em

  @include mq($from: tablet)
    overflow: hidden
    transition: 1.5s ease
    transition-delay: 2s
    min-height: calc(100vh - size(200))


    .loading &
      min-height: 100vh

    &__title
      margin-bottom: size(40)
      line-height: 1

      .h1
        text-transform: uppercase
        line-height: 1

        span
          display: block
          transition: 1s ease
          transition-delay: 1s

          &:nth-child(2)
            width: fit-content
            margin-left: auto
            margin-top: .25em

      .loading &
        .h1
          span
            opacity: 0

            &:nth-child(1)
              transform: translateX(-10%)

            &:nth-child(2)
              transform: translateX(10%)

    &__container
      align-items: center
      min-height: size(500)

    &__content
      width: 100%
      display: block
      padding-top: 5.7em

    .btn-play
      position: absolute
      right: 2.75rem
      bottom: 12rem

      .icon
        width: size(85)

    &__buttons
      margin-left: auto
      width: fit-content
      position: relative

      .btn
        margin-left: size(39)

      &-items
        transition: 1s ease
        transition-delay: 1s

        .loading &
          opacity: 0
          transform: translateY(100%)