.page-hero
  padding-top: 6.9rem
  padding-bottom: 2.8rem
  color: #FFF
  background: $lh-action

  &--slider
    padding-bottom: unset

  &__caption
    position: relative

    a
      color: currentColor

      &:hover
        text-decoration: underline

  &__title
    margin-bottom: size(52, m)

  &__description
    font-size: size(20, m)
    font-family: Poppins, sans-serif
    padding-right: size(25, m)
    margin-bottom: size(55, m)
    font-weight: 500
    line-height: 150%

  &__image
    padding-right: 1em

  &__slider
    margin-top: 2.2em
    border-bottom: 1px solid $lh-accent
    padding-bottom: .9em

    .swiper-slide
      background: #FFF
      color: #231F20

    .swiper-controls
      display: flex
      position: relative
      transform: translateY(-2em)
      z-index: 2

      .swiper-button
        top: 50%
        transform: translateY(-50%)
      
  .insights-slider
    &__item
      &-image
        position: relative

        &:before
          content: ''
          display: block
          position: absolute
          width: 100%
          height: 100%
          left: 0
          top: 0
          background: $lh-action
          z-index: 1

        &:after
          content: ''
          display: block
          padding-bottom: 63.3689%

        img
          position: absolute
          width: 100%
          height: 100%
          object-fit: cover
          z-index: 2

      &-content
        padding: size(20, m) size(20, m) size(40, m)
        font-size: size(20, m)
        line-height: 150%

      &-title
        font-size: size(16, m)
        font-weight: 600
        margin-bottom: .5em

      &-description
        margin-bottom: 1em
        font-size: size(20, m)
        font-family: Poppins, sans-serif
        font-weight: 500
        color: #014E6B

    .swiper-wrapper
      margin-bottom: 5em

      &:after
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        bottom: 0
        background: #FFF


  &--contact
    .page-hero
      &__title
        margin-bottom: 2.4em

      &__description
        font-size: size(18, m)
        font-family: Calibri, sans-serif
        line-height: 120%
        margin-bottom: 3.9375rem
        
        a
          color: #FFF

          &:hover
            text-decoration: underline

    .text-caption
      font-size: size(20, m)
      font-family: Calibri, sans-serif
      font-weight: normal
      margin-bottom: 0.5em

  &--partners
    .page-hero
      &__caption
        font-size: size(20, m)

      &__title
        .h3
          font-size: size(40, m)


  @include mq($until: tablet)
    &__slider
      display: none

    &--slider
      padding-bottom: 2.8rem

  @include mq($from: tablet)
    padding-bottom: 4.6rem
    position: relative

    &__container
      display: flex
      align-items: center
      padding-right: size(104)

    &__image
      flex: size(512) 0 0
      margin-left: auto
      padding-right: unset
      transition: 1s ease

      .img--bordered
        width: size(512)
        margin-right: unset

        img
          width: 100%

      .loading &
        opacity: 0
        transform: translateX(80px)

    &__content
      flex: size(650) 0 0
      padding-top: 1.5em

    &__caption
      transition: 1s ease

      .loading &
        opacity: 0
        transform: translateY(10%)

    &__title
      margin-bottom: size(13)
      transition: 1s ease
      
      &--wide
        margin-right: size(-25)

      .loading &
        opacity: 0
        transform: translateY(10%)

    &__description
      font-size: size(24)
      margin-bottom: unset
      padding-right: unset
      transition: 1s ease
      transition-delay: .15s

      .loading &
        opacity: 0
        transform: translateY(10%)


    &--slider
      padding-bottom: 3.1rem
      position: unset

      .page-hero
        &__content
          flex-basis: 29.5rem
          margin-bottom: 2.2em

        &__container
          flex-wrap: wrap

        &__slider
          flex: 100% 0 0
          max-width: 100%
          padding-left: size(40)
          padding-right: size(40)
          margin: unset
          background: none
          padding-bottom: unset
          border-bottom: unset
          position: relative
          transition-delay: .15s
          transition: 1s ease

          .loading &
            transform: translateY(10%)
            opacity: 0

          .swiper-controls
            display: flex
            position: absolute
            right: -3em
            left: -3em
            top: 0
            bottom: 0
            pointer-events: none
            transform: unset

          .swiper-button
            top: 5.4em
            pointer-events: auto
            transform: unset

    &--contact
      .page-hero
        &__title
          margin-bottom: 1.44em

        &__description
          font-size: size(20)
          margin-bottom: 3.9375rem
          line-height: 150%

          a
            color: #FFF

            &:hover
              text-decoration: underline

      .text-caption
        font-size: size(24)
        font-family: Poppins, sans-serif
        font-weight: 500
        margin-bottom: 0.5em


    .insights-slider
      &__item
        display: flex
        padding: unset

        &-content
          text-align: unset
          padding: 1.4em 2.4em 1.6em
          display: flex
          flex-direction: column

        &-description
          font-size: size(24)
          font-family: Poppins, sans-serif
          font-weight: 600
          padding: 0 0 .5em
          margin-bottom: auto

        &-title
          font-size: size(16)
          margin-bottom: .1em

        &-image
          flex: size(456) 0 0

      .swiper-wrapper
        margin-bottom: 3.3em
        border-radius: size(8)
        overflow: hidden

        &:after
          width: calc(100% - size(456))
          z-index: -1

      .swiper-pagination
        top: 90%
        position: absolute

    &--partners
      .page-hero
        &__caption
          font-size: size(20)
          margin-bottom: .2em

        &__title
          .h3
            font-size: size(64)

