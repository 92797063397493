.service-item
  background: #FFF
  display: flex
  border-radius: size(8, m)
  border: 4px solid transparent
  transition: .5s ease
  width: size(250, m)
  //min-height: size(300, m)
  perspective: 1000px
  flex-direction: column

  &:hover
    border-color: $lh-green

    .service-item__container
      transform: rotateY(180deg)

  &__icon
    transition: .25s ease
    overflow: hidden
    height: size(120, m)
    display: flex
    justify-content: center

    .icon
      height: auto
      width: size(120, m)

  &__title
    font-weight: 500
    font-size: size(20, m)
    text-align: center
    color: #231F20
    margin-top: 1.475rem
    margin-bottom: size(15, m)
    line-height: 150%

  &__container
    padding-left: size(25, m)
    padding-right: size(25, m)
    transition: transform 0.8s
    transform-style: preserve-3d
    display: flex
    flex: 1
    width: 100%

  &__front, &__back
    position: absolute
    width: 100%
    height: 100%
    backface-visibility: hidden
    left: 0
    top: 0
    padding-top: size(25, m)

  &__back
    transform: rotateY(180deg)
    padding-top: size(25, m)
    position: relative
    
  &__description
    line-height: 150%
    color: #231F20
    font-size: size(16, m)
    text-align: center

  &__arrow
    display: flex
    justify-content: center
    padding-top: size(10, m)
    padding-bottom: size(20, m)

    .icon
      fill: $lh-green

  @include mq($from: tablet)
    border-radius: size(8)
    width: size(250)
    min-height: size(300)
    transition: unset

    &__container
      padding-left: size(25)
      padding-right: size(25)

    &__title
      font-size: size(20)
      margin-top: 1.2rem

    &__description
      font-size: size(16)

    &__icon
      height: size(120)

      .icon
        width: size(120)

    &__arrow
      padding-top: size(10)
      padding-bottom: size(20)