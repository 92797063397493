.header
  color: #FFF
  position: fixed
  top: 0
  left: 0
  right: 0
  transition: .5s ease
  padding-top: size(22, m)
  padding-bottom: size(22, m)
  z-index: 11

  &--dark
    background: $lh-accent

  .logo
    position: relative
    z-index: 11

  &.is-fixed
    background: $lh-accent

  &__container
    display: flex
    align-items: center
    justify-content: space-between
    
  &__menu
    font-weight: 500
    font-family: Poppins, sans-serif
    opacity: 0
    transition: .25s ease
    pointer-events: none

    &.is-active
      opacity: 1
      pointer-events: auto

    a:not(.btn)
      color: #FFF

    &-item
      margin-bottom: 1.5em

      &.is-open
        .header__menu-link
          color: $lh-green

    &-link
      padding-left: size(23, m)
      padding-right: size(23, m)

      &--submenu
        display: flex
        align-items: center

        &:after
          content: ''
          display: block
          width: size(16, m)
          height: size(16, m)
          mask: url("../images/sprites/main.stack.svg#image-angle-down") 50% 50% no-repeat
          mask-size: cover
          background: currentColor
          margin-left: .25em
          margin-bottom: .1em

        &.is-active
          color: $lh-green

  &__submenu
    height: 0
    overflow: hidden
    transition: .3s ease

    &-item
      padding: .5em size(23, m)
      transition: .3s ease

      &:first-child
        margin-top: .5em

      &.is-open
        background: #006194

        .header__submenu-link
          color: $lh-green
          &:after
            transform: rotate(-180deg)

    &-link
      display: flex
      align-items: center
      max-width: 90%

      &:after
        content: ''
        display: block
        width: size(16, m)
        height: size(16, m)
        mask: url("../images/sprites/main.stack.svg#image-angle-down") 50% 50% no-repeat
        mask-size: cover
        background: currentColor
        margin-bottom: .1em
        margin-left: auto

      &--simple
        &:after
          display: none

  &__third-menu
    margin-left: size(-23, m)
    margin-right: size(-23, m)
    height: 0
    overflow: hidden
    transition: .3s ease

    ul
      padding: .5em size(23, m)

    li
      margin-top: .5em

  .burger
    width: 30px
    height: 24px
    min-width: 30px
    padding-left: 3px
    padding-right: 3px
    cursor: pointer
    position: relative
    z-index: 11

    span
      display: block
      position: absolute
      width: 24px
      height: 2px
      border-radius: 1px
      background: #FFF
      left: 3px
      transition: transform .3s ease, opacity .3s ease
      transform-origin: 50%
      pointer-events: none

      &:nth-child(1)
        top: 4px

      &:nth-child(2)
        top: calc(50% - 1px)

      &:nth-child(3)
        bottom: 4px

    &.is-active
      span
        &:nth-child(1)
          transform: rotate(135deg)
          margin-top: 7px

        &:nth-child(2)
          opacity: 0

        &:nth-child(3)
          transform: rotate(-135deg)
          margin-bottom: 7px


  &__search
    padding-left: size(8, m)
    flex-shrink: 0
    padding-top: 1rem

    &-icon
      fill: $lh-green
      width: fit-content
      padding: 1em

      .icon
        height: auto
        width: size(16, m)
        pointer-events: none

    &-holder
      position: absolute
      left: 0
      top: 100%
      right: 0
      z-index: 1
      background: #006194
      padding: size(16, m)
      opacity: 0
      pointer-events: none
      transition: .5s ease

      &.is-active
        opacity: 1
        pointer-events: auto

    &-input
      flex: 1

      .input
        color: #FFF
        background: none
        padding-left: .25em
        width: 100%
        font-weight: 500
        font-family: Poppins, sans-serif
        border: none
        outline: none

        &::placeholder
          color: #FFF

    &-container
      display: flex
      position: relative
      border-bottom: 1px solid #FFF

    &-button
      fill: $lh-green
      display: flex
      align-items: center

      .icon
        width: size(24, m)
        height: auto
        pointer-events: none

    &--mobile
      position: relative
      padding-left: size(23, m)
      padding-right: size(23, m)
      padding-top: unset

      .header__search-input
        .input
          border-bottom: 1px solid #FFF
          padding-left: 2.5em

      .header__search-icon
        position: absolute
        left: size(23, m)
        top: 0
        bottom: 0
        display: flex
        width: 2.5em
        align-items: center
        justify-content: center
        z-index: 1
        padding: .5em

        .icon
          width: size(24,m)
          height: auto


  @include mq($until: tablet)
    &__submenu
      &-item
        padding-left: size(43, m)

        &--main
          padding-left: size(23, m)

    &__third-menu
      padding-left: size(26, m)

    &__menu
      position: fixed
      top: 0
      right: 0
      bottom: 0
      left: 0
      background: $lh-accent-2
      color: #FFF
      z-index: 10
      padding-top: size(99, m)
      padding-bottom: 1rem
      display: flex
      flex-direction: column

      &-info, &-image
        display: none

      &-nav
        width: 100%
        flex-shrink: 1
        overflow: auto
        flex-grow: 1
        display: flex

        ul
          width: 100%
    &__search
      &--desktop
        display: none

  @include mq($from: tablet)
    padding-top: unset
    padding-bottom: unset

    .logo
      width: size(254)

    &__container
      padding: size(22) size(60)
      position: relative

    .burger
      display: none

    &__menu
      opacity: unset
      display: flex
      align-items: center
      pointer-events: unset
      font-size: size(16)

      &-content
        position: absolute
        top: 100%
        left: 0
        right: 0
        background: #014E6B
        display: flex
        padding-left: size(60)
        opacity: 0
        transition: .15s ease
        pointer-events: none
        z-index: 2

        &.is-open
          opacity: 1
          pointer-events: auto

      &-link
        padding-left: size(20)
        padding-right: size(20)
        transition: color .3s ease
        position: relative
        cursor: pointer

        &:before
          content: ''
          display: block
          position: absolute
          height: 100%
          width: 100%
          top: 100%
          left: 0

      &-info
        max-width: size(390)
        margin-right: auto
        padding-top: size(44)

      &-description
        font-size: size(16)
        font-family: Calibri, sans-serif
        line-height: 150%
        margin-bottom: size(40)

      &-title
        margin-bottom: size(22)

      &-nav
        > ul
          display: flex

      &-item
        margin: 0

        &:hover
          .header__menu-link
            color: $lh-green

          .header__menu-content
            opacity: 1
            pointer-events: auto

      &-image
        flex: size(483) 0 0

        img
          object-fit: cover
          width: 100%
          height: 100%

    &__submenu
      display: block
      flex: size(340) 0 0
      height: unset
      overflow: unset
      align-self: center
      opacity: unset
      pointer-events: unset

      &--align-top
        align-self: flex-start
        padding-top: 2em

      &[data-toggle-content]
        opacity: unset
        height: unset
        pointer-events: unset
        overflow: unset

      &-link
        max-width: 100%
        padding: .3em 2.75em .3em 1em
        cursor: pointer

        &:after
          transition: .5s ease
          transform: rotate(-90deg)
          width: 1em
          height: 1em

      &-item
        padding: unset

        &:first-child
          margin-top: unset

        &--main
          display: none

        &:hover
          z-index: 2
          .header__submenu-link
            color: $lh-green
            font-weight: bold
            background: #006194
            margin-right: -1em

            &:after
              transform: translateX(100%) rotate(-90deg)


          .header__third-menu
            opacity: 1
            pointer-events: auto
            z-index: 1

    &__search
      padding: unset
      margin-left: 1rem

      &-container
        width: size(1000)
        margin-left: auto
        margin-right: auto

      &--mobile
        display: none

      &-icon
        padding: unset
        cursor: pointer

      .icon
        width: size(18)

    &__third-menu
      height: unset
      overflow: unset
      position: absolute
      top: 0
      right: 0
      bottom: 0
      width: size(483)
      opacity: 0
      transition: .5s ease
      z-index: 1
      background: #006194
      margin: unset
      display: flex
      align-items: center

      &[data-toggle-content]
        height: unset
        //pointer-events: unset
        overflow: unset
      
      ul
        padding-top: unset
        padding-bottom: .75em

      a
        transition: .3s ease
        &:hover
          color: $lh-green

    &--index
      transition: transform 1s ease, opacity 1s ease, background .3s ease
      transition-delay: 1s

      .loading &
        transform: translateY(-100%)
        opacity: 0

      &.is-fixed
        transition-delay: unset

      &.scrolled-up:not(is-fixed)
        transition-delay: unset