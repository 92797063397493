.markets-services
  padding-top: 7.1em
  padding-bottom: 4em

  &__title
    margin-bottom: 3.7em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &.bg-gray
    //background: none
    //position: relative

    //&:after
      content: ''
      display: block
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      background: rgba(#E6E6EA, .5)
      z-index: -1

  @include mq($from: tablet)
    padding-bottom: 7em
    padding-top: 6.3em

    &__title
      margin-bottom: 4em