.search-results
  padding-top: 6.1em
  padding-bottom: 4.3em

  &__title
    margin-bottom: 1.9em

  &__field
    position: relative
    margin-bottom: 1.5em

    .input
      border: 1px solid $lh-green
      border-radius: size(28, m)
      padding-right: 3em
      font-family: Poppins, sans-serif

    .btn-submit
      position: absolute
      right: 0
      top: 0
      bottom: 0
      width: 3em
      display: flex
      align-items: center
      justify-content: center
      fill: $lh-green
      cursor: pointer

      .icon
        height: auto
        width: size(24, m)

  &__list
    font-size: size(20, m)

    &-item
      margin-bottom: 1.2em
      line-height: 150%
      border-bottom: 1px solid #E6E6EA
      padding-bottom: 1em

    a
      display: block
      color: #000

      &:hover
        .search-results__list-title
          color: $lh-green
      
    &-title
      color: #006194
      transition: .3s ease
      margin-bottom: .9em
      display: block


  @include mq($from: tablet)
    padding-top: 7.8em
    
    &__field
      width: size(816)
      margin-bottom: 4em

      .input
        height: size(56)
        font-size: size(16)
        padding-right: 3.4em

      .btn-submit
        width: 3.4em

        .icon
          width: size(24)

    &__container
      padding-left: size(120)

    &__title
      margin-bottom: 1.8em

      .h3
        font-size: size(40)

    &__list
      font-size: size(20)
      width: size(816)