.socials
  display: flex
  align-items: center
  
  li
    margin-right: 1em

  .icon
    height: auto
    width: size(32, m)

  a
    &:hover
      text-decoration: none

  @include mq($from: tablet)
    .icon
      width: size(32)

    li
      margin: 0 0 0 0.6em