.pagination
  display: flex
  align-items: center
  justify-content: center

  .swiper-button
    .icon
      width: 3em

  &__list
    display: flex
    align-items: center
    font-weight: 600
    color: $lh-accent
    font-family: Poppins, sans-serif
    font-size: size(24, m)

  &__prev,&__next
    width: size(40, m)
    min-width: size(40, m)
    height: size(40, m)
    border-radius: 50%
    background: $lh-green
    display: flex
    align-items: center
    justify-content: center
    transition: .25s ease

    .icon
      height: auto
      width: size(15, m)
      fill: #FFF

    &:hover
      background: $lh-action

  &__prev
    padding-right: .2em
    margin-right: .5em

  &__next
    padding-left: .2em
    margin-left: .5em

  &__item
    display: flex
    padding: 0 .3em
    margin-left: .2em
    margin-right: .2em
    width: size(40, m)
    min-width: size(40, m)
    height: size(40, m)
    border-radius: 50%
    transition: .25s ease

    a, span
      align-items: center
      justify-content: center
      display: flex
      width: 100%


    &:hover
      a
        color: $lh-green

    &.is-active
      background: $lh-accent
      color: #FFF

  @include mq($from: tablet)
    .swiper-button
      .icon
        width: 5em

    &__list
      font-size: size(20)
      margin-left: 1em
      margin-right: 1em

    &__item
      margin-left: .65em
      margin-right: .65em