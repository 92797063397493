.services
  $services: &

  &__item
    margin-bottom: size(24, m)
    border-radius: size(8, m)
    overflow: hidden
    
    &.is-open
      .services__control
        background: #006194
        color: #FFF

        .icon
          fill: #FFF

  &__control
    border: 1px solid $lh-accent
    border-radius: size(8, m)
    display: flex
    align-items: center
    padding: size(23) size(18) size(23) size(16)
    cursor: pointer
    color: $lh-action
    font-size: size(18, m)
    line-height: 150%
    background: #FFF

  &__icon
    display: flex
    align-items: center
    justify-content: center
    flex: size(48, m) 0 0
    margin-right: 1em

    .icon
      height: auto
      width: size(40, m)
      fill: #063A59

  &__content
    background: rgba(#006194, .1)
    border-radius: 0 0 size(8, m) size(8, m)
    padding: 0 size(20, m) size(20, m)
    position: relative
    margin-top: size(12, m)

  &__image
    margin-left: size(-20, m)
    margin-right: size(-20, m)
    border-radius: 0 0 size(8, m) size(8, m)
    position: relative
    margin-bottom: size(20, m)
    overflow: hidden

    &:after
      content: ''
      display: block
      padding-bottom: 73.4693%

    img
      width: 100%
      height: 100%
      object-fit: cover
      position: absolute

  &__caption
    line-height: 150%
    font-weight: 500
    margin-bottom: size(18, m)

  &__description
    line-height: 150%
    font-size: size(20, m)
    margin-bottom: size(48, m)

  .btn
    min-width: size(230, m)
    margin-left: auto
    margin-right: auto
    display: flex

  &--v2
    #{$services}
      &__control
        justify-content: center
        padding: 1.75rem .9rem 1.75rem .9rem

      &__content
        margin-top: unset

      &__description
        margin-bottom: unset


  @include mq($until: tablet)
    &__item
      &.is-open
        .services
          &__control
            border-radius: size(8, m) size(8, m) 0 0

  @include mq($from: tablet)
    position: relative

    &__item
      width: size(464)
      overflow: unset
      margin-bottom: .9em

    &__icon
      flex-basis: size(100)
      margin-right: unset

      .icon
        width: size(48)

    &__title
      font-size: size(20)
      font-weight: 500
      
    &__control
      padding-top: size(15)
      padding-bottom: size(17)
      transition: background-color .3s ease

      &:hover
        background: rgba(#006194, .1)

    &__container
      position: absolute
      left: calc(100% - size(596))
      top: 0
      right: 0
      min-height: 100%
      border-radius: size(8)
      overflow: hidden
      transition: opacity .3s ease !important
      background: rgba(#006194, .1)

    &__content
      margin-top: unset
      border-radius: size(8)
      overflow: hidden
      padding-left: size(40)
      padding-right: size(40)
      padding-bottom: size(40)
      min-height: 100%
      background: none

    &__image
      margin-bottom: size(42)
      margin-left: size(-40)
      margin-right: size(-40)

      &:after
        padding-bottom: 41.275167%

    &__description
      font-size: size(20)
      margin-bottom: 2.6rem

    &__caption
      margin-bottom: size(18.5)

    .btn
      min-width: unset
      margin-left: unset

    &--v2
      display: flex
      justify-content: space-between

      #{$services}
        &__item
          width: size(408)
          margin-bottom: unset
          
        &__control
          padding-top: size(27)
          padding-bottom: size(27)

        &__container
          left: 0
          right: 0
          top: 8.3333vw
          border-radius: size(8)
          min-height: unset

        &__content
          display: flex
          align-items: center
          padding: unset

        &__image
          flex: size(408) 0 0
          border-radius: size(8)
          margin: 0 size(80) 0 0

          &:after
            padding-bottom: 86.764705%

        &__description
          padding: 1em size(112) 1em 0

