.article
  padding-top: 6em
  position: relative

  h1
    font-size: size(40, m)
    color: $lh-action
    margin-bottom: .05em

  h2
    color: $lh-action
    font-size: size(24, m)
    margin-bottom: 2.1em
    margin-top: .5em
    line-height: 150%

  h3
    color: $lh-action
    font-size: size(20, m)
    margin-bottom: 2.3em
    margin-top: 2em
    line-height: 150%

  h4
    color: $lh-action
    font-size: size(18, m)
    margin-bottom: .5em
    margin-top: .5em
    line-height: 150%

  &__caption
    font-size: size(20, m)
    margin-bottom: .7em

  &__data
    color: #8B939B
    font-size: size(20, m)
    line-height: 150%
    display: flex
    flex-wrap: wrap
    margin-bottom: .8em

    li
      &:not(:last-child)
        &:after
          content: ''
          display: inline-block
          width: size(8, m)
          height: size(8, m)
          border-radius: 50%
          background: currentColor
          margin-left: 1em
          margin-right: 1em

  &__transcript
    position: relative

    &:after
      opacity: 0
      content: ''
      display: block
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      transition: .5s ease
      pointer-events: none

    &.is-active
      &:after
        opacity: 1
        pointer-events: auto

      .article__transcript-content
        max-height: 10em
        overflow: hidden

    &-button
      display: none

      &.is-active
        display: block

  img
    border-radius: size(8, m)
    display: block
    max-width: 100%
    height: auto
    width: auto

  &__hero
    margin-bottom: 2em

    img
      width: 100%
      display: block

  &__share
    margin-bottom: 5.9em

    &--desktop
      display: none
      margin-bottom: unset

    &-caption
      font-size: size(20, m)
      font-family: Poppins, sans-serif
      font-weight: 500
      margin-bottom: .4em



  .socials
    a
      display: flex
      align-items: center
      justify-content: center
      width: size(48, m)
      height: size(48, m)
      border-radius: 50%
      background: #00394F
      transition: .3s ease

      .icon
        fill: #FFF
        height: auto
        width: size(26, m)

      &:hover
        background: $lh-green

  &__post
    p
      margin-bottom: 1em

    img
      margin-top: 2em
      margin-bottom: 2em

  .blockquote
    border: 1px solid #006194
    border-radius: size(8, m)
    position: relative
    padding: 3.8em 1em 4.2em
    font-family: Poppins, sans-serif
    font-size: size(18, m)
    font-weight: 600
    line-height: 120%
    color: #006194
    text-align: center
    margin-bottom: size(56, m)
    margin-top: size(56, m)

    &--dark
      background: #006194
      color: #FFF

    &:before,&:after
      content: '“'
      color: $lh-green
      display: block
      font-size: size(64, m)
      width: fit-content
      line-height: normal
      position: absolute
      left: .3em
      top: .1em
      font-family: Poppins, sans-serif

    &:after
      content: '“'
      margin-left: auto
      left: unset
      top: unset
      right: .4em
      bottom: -.55em


  &__slider
    margin-left: size(-16, m)
    margin-right: size(-16, m)
    margin-bottom: 3em

    .swiper-slide
      width: auto
      
    &-item
      position: relative
      margin-left: size(12, m)
      margin-right: size(12, m)
      width: size(240, m)

      &:after
        content: ''
        display: block
        padding-bottom: 87.5%

      img
        position: absolute
        width: 100%
        height: 100%
        object-fit: cover
        margin: unset

    .swiper-pagination
      position: unset
      margin-top: .85em
      
      &-bullet
        background: #DDD
        border-color: #DDD

        &-active
          background: $lh-green
          border-color: $lh-green

  @include mq($from: tablet)
    padding-top: 9.5em
    padding-bottom: 3em

    &__caption
      font-size: size(20)
      margin-bottom: .2em

    &__container
      padding-left: size(212)
      padding-right: 0
      display: flex
      flex-direction: row-reverse

    &__data
      font-size: 1em
      margin-bottom: 2em

      li:not(:last-child)
        &:after
          width: size(8)
          height: size(8)

    h1
      font-size: size(64)
      margin-bottom: .2em

    img
      border-radius: size(8)

    &__content
      flex: calc(100% - size(212)) 0 0
      max-width: calc(100% - size(212))

    &__share

      &-caption
        font-size: size(20)
        text-align: center
        width: size(80)
        margin-left: auto
        margin-bottom: .9em

      &--desktop
        display: block
        flex: size(212) 0 0
        padding-right: size(30)
        padding-top: 25.2vh

      &--mobile
        display: none

      &-container
        position: sticky
        top: size(90)

      .socials
        display: block
        width: fit-content
        margin-left: auto

        li
          margin: 0 0 1em

        a
          width: size(80)
          height: size(80)

          .icon
            width: size(36)

    &__hero
      margin-bottom: 4em

    &__post
      margin-left: size(126)
      margin-right: size(126)
      font-size: size(16)
      line-height: 150%

      h2
        font-size: size(40)
        margin-bottom: 0.4em

      h3
        font-size: size(20)
        font-weight: 500
        margin-top: 1em
        margin-bottom: 1em

      img
        margin-bottom: 2.5em
        margin-top: 2.5em

    &__slider
      margin-top: 2.5em
      margin-bottom: 1.8em

      img
        margin: unset

      &-item
        width: size(240)
        margin-right: size(12)
        margin-left: size(12)

      .swiper-pagination
        &-bullet
          width: size(8)
          height: size(8)

    .img-right
      float: right
      width: size(365)
      margin-left: size(6)

      img
        width: size(370)

    .img-left
      float: left
      width: size(365)
      margin-right: size(6)

      img
        width: size(370)


    .blockquote
      margin-bottom: size(40)
      margin-top: size(40)

      &--right
        float: right
        width: size(365)
        max-width: size(365)
        margin-left: size(6)
        clear: right

      &--left
        float: left
        width: size(365)
        max-width: size(365)
        margin-right: size(6)
        clear: left

    .column-2
      column-count: 2
      column-gap: 2em
      clear: both