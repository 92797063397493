.insights-updates
  padding-top: 4.7em
  padding-bottom: 5em

  &__title
    text-align: center
    margin-bottom: 4.25em

  &__header
    margin-bottom: size(56, m)
    position: relative
    z-index: 2

  &__list
    .insights__list
      &-arrow
        display: flex

      &-item
        margin-bottom: size(72, m)
        width: unset

  @include mq($until: tablet)
    &__list
      .insights__list
        &-content
          padding-bottom: 3em

  @include mq($from: tablet)
    padding-top: 6.5em

    &__header
      display: flex
      justify-content: space-between
      margin-bottom: 4.5rem
      align-items: center

    &__sorter
      min-width: size(297)

    &__title
      margin-bottom: unset

    &__container
      padding-left: size(120)
      padding-right: size(120)

    &__list
      .insights__list-item
        width: size(380)

      .grid-sizer
        width: size(378)

      .gutter-sizer
        width: size(25)