.insights
  padding-top: 6rem
  padding-bottom: 5.5rem
  position: relative

  &:after
    content: ''
    display: block
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: rgba(#E6E6EA, .5)
    z-index: -1

  &.bg-none
    &:after
      display: none


  &__title
    text-align: center
    margin-bottom: 2.9em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__list
    margin-bottom: 3.5em

    .swiper-slide
      display: flex
      justify-content: center
      height: auto

    .swiper-wrapper
      padding-bottom: 1.5em

    &-item
      width: size(275, m)
      margin-left: auto
      margin-right: auto
      background: #FFF
      border-radius: size(8, m)
      display: flex
      flex-direction: column

      &--bordered
        border: 1px solid $lh-accent
      
    &-image
      position: relative
      border-radius: size(8, m)
      overflow: hidden
      width: 100%
      
      &:after
        content: ''
        display: block
        padding-bottom: 58.909091%

      img
        width: 100%
        height: 100%
        object-fit: cover
        position: absolute

    &-title
      font-size: size(20, m)
      color: $lh-accent
      font-weight: 500
      line-height: 150%
      margin-bottom: .15em

    &-caption
      font-size: size(12, m)
      margin-bottom: .1em
      
    &-content
      padding: size(12, m)  size(16, m) 2.2em
      position: relative
      color: #000
      flex: 1

    &-data
      color: #8B939B
      margin-bottom: 1.3em

      span
        display: inline-flex
        align-items: center

        &:not(:last-child)
          &:after
            content: ''
            display: block
            width: size(4,m)
            height: size(4, m)
            border-radius: 50%
            background: currentColor
            margin-left: .7em
            margin-right: .8em

    &-arrow
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      bottom: 0
      left: 50%
      width: size(56)
      height: size(56)
      border-radius: 50%
      transform: translate(-50%, 50%)
      background: $lh-action

      .icon
        transform: rotate(-45deg)
        fill: #FFF

    &.sliderEpisodes
      margin-left: size(-16, m)
      margin-right: size(-16, m)

      .swiper-slide
        width: unset
        padding-left: size(12)
        padding-right: size(12)

      .insights__list
        &-item
          border: 1px solid #014E6B

        &-content
          padding-top: 1.5em

      ~ .swiper-controls
        display: none

  &__more
    display: flex
    justify-content: center

  .swiper-controls
    position: absolute
    top: 0
    left: 0
    right: 0
    justify-content: space-between
    display: flex
    pointer-events: none

    .swiper-button
      pointer-events: auto


  @include mq($until: tablet)
    &__list
      &-item
        &:visited
          .insights__list-arrow
            background: $lh-green

    .swiper-controls
      bottom: 0
      align-items: center

    .swiper-button
      position: unset
      transform: translateY(-50%)

  @include mq($from: tablet)
    padding-top: 6.3rem
    padding-bottom: 4rem

    .container
      padding-left: size(120)
      padding-right: size(120)
      position: relative
      z-index: 1

    &__title
      margin-bottom: 3.8em

    &__list
      margin-bottom: 0.9em

      .swiper-wrapper
        padding-bottom: 3rem

      &-item
        width: size(380)

        &:hover
          .insights__list-image
            img
              transform: scale(1.05)

          .insights__list-arrow
            background: $lh-green


        
      &-image
        overflow: hidden

        &:after
          padding-bottom: 52.631578%

        img
          transition: 2s ease

      &-content
        padding: size(35) size(40) size(60)

      &-caption
        font-size: size(12)
        margin-bottom: unset

      &-title
        font-size: size(20)
        margin-bottom: -.15em

      &-data
        font-size: size(16)
        margin-bottom: 1.1em

      &-description
        font-size: size(16)
        line-height: 150%

      &-arrow
        display: flex
        transition: .25s ease

      &.sliderEpisodes
        margin-left: unset
        margin-right: unset

        .swiper-slide
          padding-left: unset
          padding-right: unset

        ~ .swiper-controls
          display: flex

    .swiper-controls
      left: size(-100)
      right: size(-100)

    .swiper-button
      top: 8.5em
      z-index: 1