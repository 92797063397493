.loader
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: #FFF
  z-index: 99
  display: flex
  overflow: hidden
  transition: 1s ease
  transition-delay: .9s
  opacity: 0
  pointer-events: none


  &__container
    display: flex
    align-items: center
    justify-content: center
    width: 100%

  svg
    display: block
    width: 20%
    transition: 1s ease
    transform: scale(20)
    opacity: 0
    transition-delay: .9s

    path
      transition: .2s ease

      @for $i from 1 through 10
        &:nth-of-type(n + #{$i})
          transition-delay: ($i)*0.1+s


  .loading &
    opacity: 1

    svg
      transform: scale(1)
      opacity: 1

      path
        opacity: .15