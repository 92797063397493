.contract-list
  padding-top: 4.7em
  padding-bottom: 1em

  &__title
    margin-bottom: 3.3em

  &__item
    border: 1px solid $lh-accent
    border-radius: size(8)
    margin-bottom: 4.8em
    padding: size(35, m) 1em size(42, m)
    position: relative

    &.is-open
      .contract-list__item-toggler
        &:after
          opacity: 0

    &-logo
      margin-bottom: 1.5em

      img
        max-height: size(73, m)
        max-width: 100%

    &-content
      color: #231F20

      a
        text-decoration: underline
        color: #006194

        &:hover
          text-decoration: none

    &-toggler
      width: size(48, m)
      height: size(48, m)
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      background: #063A59
      position: absolute
      top: 100%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
      cursor: pointer
      transition: .25s ease

      &:hover
        background: $lh-green

      &:after, &:before
        content: ''
        display: block
        height: size(4, m)
        width: size(18, m)
        background: #FFF
        position: absolute

      &:after
        transform: rotate(-90deg)

    &-title
      font-size: size(20, m)
      font-weight: 500
      margin-bottom: .5em

    &-info
      padding-top: 2em

    &-title
      margin-bottom: .5em

    &-properties
      margin-bottom: size(27, m)

      li
        margin-bottom: size(8, m)

  &__items
    padding-left: 1.3em
    padding-right: 1.3em

  @include mq($from: tablet)
    padding-top: 6.2em

    &__title
      margin-bottom: 3.8em

      .h4
        font-size: size(40)

    &__items
      padding-right: size(50)
      padding-left: size(50)
      display: flex
      justify-content: space-between
      align-items: flex-start
      flex-wrap: wrap

    &__item
      flex: size(560) 0 0
      padding: 2rem 2em 2.7rem

      &-description
        font-size: size(16)

      &-content
        font-size: size(16)

      &-logo
        margin-bottom: 1.4em

        img
          max-height: size(125)

      &-title
        font-size: size(20)
        margin-bottom: 0.3em

      &-info
        padding-top: 2.3em