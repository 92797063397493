.careers-esop
  background: #D9D9D9
  padding-top: 4.4em
  padding-bottom: 2.4em

  &__title
    margin-bottom: 2.3em

  &__image
    margin-bottom: 3.9em

  &__description
    font-size: size(20, m)
    line-height: 150%

  @include mq($from: tablet)
    padding-top: 6em
    padding-bottom: 6.9em

    &__container
      display: grid
      grid-template-areas: "image title" "image description"
      grid-template-columns: size(580) size(550)
      grid-template-rows: auto auto
      justify-content: space-between
      align-items: center

    &__title
      grid-area: title
      margin-top: auto
      margin-bottom: 2em

    &__image
      grid-area: image
      margin-bottom: unset

      .img--bordered
        width: 100%

        img
          display: block
          width: 100%

    &__description
      grid-area: description
      font-size: size(20)
      margin-bottom: auto