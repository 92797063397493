.index-challenge
  padding-top: 4.6rem
  padding-bottom: size(85, m)

  &__title
    margin-bottom: 2em

  &__description
    font-size: size(20, m)
    margin-bottom: 2.7em

  &__image
    margin-bottom: 4.5rem

  &__stats
    padding-left: .7em
    margin-bottom: 4.2em

  &__more
    display: flex
    justify-content: center

  @include mq($from: tablet)
    padding-top: 6.3rem
    padding-bottom: 6.1rem

    &__container
      display: grid
      grid-template-columns: size(510) size(620)
      justify-content: space-between
      grid-template-rows: repeat(4, auto) 1fr
      grid-template-areas: "title image" "description image" "stats image" "more image"

    &__title
      grid-area: title

    &__description
      grid-area: description
      font-size: size(20)
      margin-bottom: .6em

    &__image
      grid-area: image
      padding-top: 6.4em
      margin-bottom: unset
      flex: size(620) 0 0
      margin-left: auto
      width: 100%

      .img--bordered
        position: sticky
        top: 5em
        width: 100%

        img
          width: 100%

    &__stats
      grid-area: stats
      padding-left: unset
      margin-bottom: 2.2em

    &__more
      grid-area: more
      justify-content: unset

    .stats
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      margin-right: size(-50)

      &__item
        margin-bottom: 1.7em
        flex: calc(50% - size(24)) 0 0

        &:nth-child(1)
          order: 1

        &:nth-child(2)
          order: 3

        &:nth-child(3)
          order: 2

        &:nth-child(4)
          order: 4

        &-value
          flex-basis: 50%
          margin-right: unset
          padding-top: .15em
          font-size: size(50)

        &-label
          //max-width: 37%
          font-weight: 500
          font-family: Poppins, sans-serif
          font-size: size(18)
          line-height: 120%