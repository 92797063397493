.careers-giving
  padding-top: 5em
  padding-bottom: 4.8em

  &__title
    margin-bottom: 1.3em

  &__description
    font-size: size(20, m)
    margin-bottom: 3.7em

  &__logos
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: space-between

    &-item
      margin-bottom: 2em
      flex: 45% 0 0
      display: flex
      align-items: center
      justify-content: center

      &:nth-child(even)
        transform: translateY(57%)

      img
        max-width: 90%
        max-height: size(100, m)

  @include mq($from: tablet)
    padding-top: 8.3em
    padding-bottom: 5.1em

    &__container
      display: flex

    &__content
      flex: size(580) 0 0

    &__description
      font-size: size(20)

    &__title
      margin-bottom: 1em

    &__logos
      flex: size(530) 0 0
      margin-left: auto
      margin-top: -2.35em

      &-item
        margin-bottom: size(20)
        height: size(160)

        img
          max-width: 100%
          max-height: unset