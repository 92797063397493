.partners-software
  padding-top: 2.2em
  padding-bottom: 3.7em

  &__title
    margin-bottom: .9em
    padding-right: 5em

  &__description
    font-size: size(20, m)
    margin-bottom: 3.9em

  &__list
    &-item
      margin-bottom: 3.1rem
      display: flex
      justify-content: center

  @include mq($from: tablet)
    padding-top: 3.8em
    padding-bottom: 6.2em

    &__description
      font-size: size(20)
      width: size(580)
      margin-bottom: 1.9em

    &__title
      margin-bottom: 1em

    &__list
      display: flex
      justify-content: space-between
      align-items: center

      &-item
        margin: unset