.index-solutions
  padding-top: 4.6rem
  padding-bottom: 4.5rem
  background: $lh-action
  color: #FFF

  &__title
    margin-bottom: 2.9em

  &__description
    font-size: size(20, m)

    p
      margin-bottom: unset

  &__list
    margin-left: size(-16, m)
    margin-right: size(-16, m)
    margin-bottom: 0.5em

    $list: &

    &-item
      width: size(255, m)
      height: size(255, m)
      border-radius: size(8, m)
      color: #00394F
      perspective: 1000px

      &:hover
        #{$list}
          &-container
            transform: rotateY(180deg)

    &-container
      position: relative
      width: 100%
      height: 100%
      text-align: center
      transition: transform 0.8s
      transform-style: preserve-3d

    &-content, &-image
      padding: 1em
      display: flex
      align-items: center
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      backface-visibility: hidden
      background: #FFF
      border-radius: size(8, m)

    &-content
      transform: rotateY(180deg)
      text-align: left
      font-size: size(14.5, m)
      line-height: 120%

    &-image
      display: flex
      justify-content: center

      img
        max-height: 90%


    .swiper-wrapper
      padding-top: 3em
      padding-bottom: 3em

    .swiper-slide
      width: auto
      padding-left: size(16, m)

  @include mq($from: tablet)
    padding-top: 4.1rem
    padding-bottom: 3.9rem

    &__container
      display: grid
      grid-template-rows: auto auto
      grid-template-columns: size(500) auto
      grid-template-areas: "header list" "button list"

    &__header
      grid-area: header
      margin-top: auto

    &__title
      margin: 0 0 size(40)

    &__description
      font-size: size(20)
      margin-bottom: size(80)

    &__button
      grid-area: button
      margin-bottom: auto

    &__list
      grid-area: list
      margin: 0 0 0 auto
      overflow: unset

      &-item
        width: size(350)
        height: size(350)

      .swiper-wrapper
        display: grid
        grid-template-columns: auto auto
        grid-template-rows: auto auto
        grid-gap: size(20)
        align-items: center
        grid-template-areas: "first second" "third second"
        padding: unset
        overflow: unset

      .swiper-slide
        height: auto
        padding-left: unset

        &:nth-child(1)
          grid-area: first

        &:nth-child(2)
          grid-area: second

        &:nth-child(3)
          grid-area: third



