.service-solutions
  background: #E6E6EA
  padding-top: 4.1rem
  padding-bottom: 4rem

  &__title
    text-align: center
    margin-bottom: 4.7em

    .h3
      font-weight: 500
      line-height: 150%

  &__list
    &-item
      width: size(286, m)
      margin-left: auto
      margin-right: auto
      min-height: size(318, m)
      perspective: 1000px
      display: flex

      &:hover
        .service-solutions__list-content
          transform: rotateY(180deg)

    &-content
      position: relative
      width: 100%
      text-align: center
      transition: transform 0.8s
      transform-style: preserve-3d

    &-front, &-back
      position: absolute
      width: 100%
      height: 100%
      backface-visibility: hidden

    &-front
      background: #FFF
      color: #014E6B

    &-back
      transform: rotateY(180deg)
      background: #006194
      position: relative
      color: #FFF
      text-align: center
      display: flex
      align-items: center

    &-description
      padding-left: 1.5em
      padding-right: 1.5em
      line-height: 150%

    &-arrow
      position: absolute
      width: size(48, m)
      height: size(48, m)
      display: flex
      align-items: center
      justify-content: center
      background: $lh-action
      border-radius: 50%
      left: 50%
      top: 100%
      transform: translate(-50%, -50%)

      .icon
        fill: #FFF
        height: auto
        width: size(26, m)
        transform: rotate(-45deg)

    &-icon
      display: flex
      align-items: center
      justify-content: center
      height: size(200, m)
      padding-top: 3em

      img
        width: auto
        height: size(70, m)

    &-title
      .h4
        font-size: size(18, m)
        font-weight: 500


  @include mq($until: tablet)
    &__list
      overflow: unset

      .swiper-slide
        margin-bottom: size(56, m)

      .swiper-wrapper
        display: block
        overflow: unset

      .swiper-controls
        display: none

      &-icon
        img
          height: 5.25rem

      &-title
        .h4
          font-size: 1.35rem

  @include mq($from: tablet)
    padding-top: 5.75rem
    padding-bottom: 4.4rem

    &__title
      margin-bottom: 1.5em

      .h3
        font-size: size(40)
        font-weight: 600

    &__list
      position: relative
      padding-left: size(142.5)
      padding-right: size(142.5)

      &-icon
        padding-top: 0.6em
        height: 10.4rem

        img
          height: size(120)

      &-description
        font-size: size(18)

      &-title
        padding-left: 1em
        padding-right: 1em

        .h4
          font-size: size(32)
          font-weight: 500
          line-height: 150%

      .swiper-slide
        width: unset
        height: unset
        padding-right: size(17.5)
        padding-left: size(17.5)

      .swiper-wrapper
        padding-top: 2em
        padding-bottom: 2em
        margin-left: auto
        margin-right: auto
        width: fit-content

      &-item
        width: size(350)
        min-height: size(350)

      .swiper-controls
        position: absolute
        top: 50%
        transform: translateY(-50%)
        pointer-events: none
        right: size(30)
        left: size(30)
        display: flex
        justify-content: space-between


      .swiper-button
        pointer-events: auto
        position: unset