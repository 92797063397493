.cta
  background: $lh-green
  color: #FFF
  //padding-top: 6.2em
  //padding-bottom: 7em
  padding-top: 3em
  padding-bottom: 3em
  position: relative

  &__left
    margin-bottom: 2.35em

  &__description
    line-height: 150%
    font-family: Poppins, sans-serif
    font-weight: 500
    margin-top: .3em

  &__contacts
    &-title
      font-size: size(24, m)
      font-weight: bold
      font-family: Poppins, sans-serif

    &-item
      font-size: size(20, m)
      line-height: 150%

    a
      color: #FFF
    
  &--contacts
    padding-top: 2.9em
    padding-bottom: 3.5em

    .cta__container
      padding-left: size(40, m)
      padding-right: size(40, m)

    .cta__left
      margin-bottom: 2.15em

  &--partners
    background: #006194



  @include mq($from: tablet)
    padding-top: 3.2em
    padding-bottom: 2.7em

    &__container
      display: flex
      align-items: center
      justify-content: space-between
      padding-left: size(140)
      padding-right: size(140)

    &__left
      margin-bottom: unset

    &__description
      font-size: size(24)
      width: size(735)
      margin-top: .8em

    &__contacts
      &-item
        font-size: size(24)
        font-family: Poppins, sans-serif

      &-title
        font-size: size(24)
        margin-bottom: unset

    &--contacts
      padding-top: 3.4em
      padding-bottom: 2.8em

      .cta__container
        padding-left: size(224)
        padding-right: size(120)
        display: block

      .cta__left
        margin-bottom: .75em

    &--join
      padding-top: 4.4em
      padding-bottom: 4.2em

      .cta__title
        margin-bottom: -0.6em

      .cta__description
        font-size: size(20)
        font-family: Calibri, sans-serif
        width: size(652)

    &--partners
      padding-top: 4.5em
      padding-bottom: 4.2em

      .cta__title
        margin-bottom: -0.6em

      .cta__description
        font-size: size(20)
        font-family: Calibri, sans-serif
        width: size(652)

    &--make
      .cta__container
        display: block
        padding-left: size(195)
        padding-right: size(195)

      .cta__content
        display: flex
        justify-content: space-between
        align-items: center

      .cta__description
        margin: unset
        font-size: size(20)
        width: unset
        font-family: Calibri, sans-serif

      .cta__left
        width: size(652)

      .cta__title
        margin-bottom: size(40)