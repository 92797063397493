.service-complimentary
  background: $lh-accent
  color: #FFF
  padding-top: 4.8em
  padding-bottom: 2.5em

  &__title
    margin-bottom: 3.3em
    text-align: center

    .h3
      font-size: size(40, m)

  &__list
    .service-item
      margin-bottom: 3.2em
      margin-left: auto
      margin-right: auto

  @include mq($from: tablet)
    padding-top: 6em
    padding-bottom: 6em

    &__container
      display: flex
      align-items: center

    &__content
      margin-left: auto
      flex: size(843) 0 0

    &__list
      display: flex
      justify-content: space-between

      .service-item
        margin-bottom: unset

        &:last-child
          margin-right: unset

    &__title
      margin-bottom: unset
      text-align: unset
      flex: size(395) 0 0

      .h3
        font-size: size(40)