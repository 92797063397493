.input
  height: size(48, m)
  background: #FFF
  border-radius: 0
  padding-left: size(10, m)
  outline: none
  box-shadow: none
  border: none
  line-height: normal
  width: 100%
  max-width: 100%
  display: block
  color: #00394F

  &::placeholder
    color: #707377

  &.wpcf7-textarea
    height: size(110, 400)
    resize: none
    padding-top: .65em

  &[type="file"]
    font-size: 0

    &::file-selector-button
      display: none

  @include mq($from: tablet)
    height: size(48)
    padding-left: size(16)

    &.wpcf7-textarea
      height: size(110)


.input-file
  &__container
    position: relative

  &__label
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: center
    justify-content: space-between
    padding-left: 1em
    color: rgba(#000, .6)
    font-size: inherit
    pointer-events: none

    &:after
      content: ''
      display: block
      width: 3.2em
      height: 1.5em
      background: rgba(#000, .6)
      mask: url("../images/sprites/main.stack.svg#image-plus") 50% 50% no-repeat
      mask-size: contain


.form
  .input
    border: 1px solid #063A59
    border-radius: size(8, m)
    height: size(40, m)
    
  textarea.input
    height: size(265, m)

  &__row
    margin-bottom: 1em

  &__label
    font-weight: 500
    font-family: Poppins, sans-serif
    display: block
    margin-bottom: .3em

    &--requared
      &:after
        content: '*'
        color: $lh-green
        margin-left: .25em

  &__button
    margin-top: size(17, m)
    display: flex
    justify-content: flex-end

  .choices
    &__inner
      height: size(40, m)
      border-radius: size(8, m)

    &.is-open
      .choices__inner
        border-radius: size(8, m)
    

  @include mq($from: tablet)
    .input
      border-radius: size(8)
      height: size(49)
      font-size: size(16)

    textarea.input
      height: size(265)

    &__label
      font-size: size(16)

    &__row
      //margin-bottom: .7em


.wpcf7-checkbox
  position: relative

  .wpcf7-list-item-label
    position: relative
    padding-left: size(40, 400)
    display: inline-flex
    text-transform: none
    font-size: size(18, 400)
    font-weight: 500
    cursor: pointer

    &:before
      content: ''
      display: block
      width: size(24, 400)
      height: size(24, 400)
      position: absolute
      top: .1em
      background: #FFF
      mask: url("../images/sprites/main.stack.svg#image-check") 50% 50% no-repeat
      mask-size: 60% 60%
      opacity: 0
      left: 0
      z-index: 1

    &:after
      content: ''
      display: block
      width: size(24, 400)
      height: size(24, 400)
      border: 1px solid $lh-action
      border-radius: .2em
      position: absolute
      top: .1em
      left: 0

  input[type="checkbox"]
    position: absolute
    left: 0
    top: 0
    inset: -1px
    appearance: none
    width: 0
    height: 0
    overflow: hidden
    opacity: 0
    z-index: -1

    &:checked
      ~ .wpcf7-list-item-label
        &:after
          background: $lh-action

        &:before
          opacity: 1

  @include mq($from: tablet)
    .wpcf7-list-item-label
      padding-left: size(40)
      font-size: size(18)

      &:before
        width: size(24)
        height: size(24)

      &:after
        width: size(24)
        height: size(24)