// Comment out the following line (import normalize) if you are using Tailwind CSS (since it already has its own normalize built in)!
@use 'sass:math'
@import "./vars"
@import "../../libs/sass-mq/mq.import"
@import "../../libs/sass-mq/mq"
@import "../../libs/swiper/swiper-bundle"
@import "../../libs/choices.js/public/assets/styles/choices"
@import "../../libs/@splidejs/splide/dist/css/splide.min"
@import "../../libs/@fancyapps/ui/dist/fancybox/fancybox"
//@import "./normalize"

@import "./mixins"
@import "./fonts"
@import "./sprite_generated"

*, *:after, *:before
  box-sizing: border-box
  min-height: 0.001vw

.loading *
  transition: none


html
  font-size: 4.266667vw

  @include mq($from: tablet)
    font-size: 1.388889vw

body
  display: flex
  flex-direction: column
  background: #FFF
  color: #231F20
  font-family: "Calibri", sans-serif
  width: 100%
  height: 100%
  min-height: 100vh
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  line-height: 150%


img
  max-width: 100%
  display: block

.container
  width: 100%
  min-width: 280px
  margin: 0 auto
  padding-right: size(20, m)
  padding-left: size(20, m)

  @include mq($from: tablet)
    padding-left: size(80)
    padding-right: size(80)

.pin-spacer
  pointer-events: none

  &:has(.page-logo--right)
    max-width: 70.825vw

.img-responsive
  width: auto
  height: auto
  max-width: 100%
  display: block

  img
    max-width: 100%
    display: block

.js-typed
  color: transparent !important
  position: relative

  &:before
    content: attr(data-type)
    position: absolute
    left: 0
    top: 0
    right: 0

  &.text-accent-2
    &:before
      color: $lh-accent-2

  &.text-action
    &:before
      color: $lh-action

  &.text-green
    &:before
      color: $lh-green

  &.text-white
    &:before
      color: #FFF


.logo
  width: size(172, m)
  background: url("../images/logo.svg") 50% 50% no-repeat
  background-size: contain
  display: block

  &:after
    content: ''
    padding-bottom: 13.953488%
    display: block

.page-logo
  position: absolute
  pointer-events: none
  z-index: 0
  max-width: 100%

  &__image
    background: url("../images/circle.svg") 50% 50% no-repeat
    background-size: cover
    width: size(275, m)
    opacity: .15

    &:after
      content: ''
      display: block
      padding-bottom: 100%

  &--left
    .page-logo__image
      transform: translateX(-50%)

  &--right
    right: 0
    overflow: hidden
    .page-logo__image
      transform: translateX(50%)

  &--static
    top: 116%

  @include mq($from: tablet)
    &__image
      width: size(1020)

a
  text-decoration: none
  color: $lh-accent
  transition: color .3s ease

  .icon
    fill: currentColor
  
.h-underlined
  &:after
    content: ''
    display: block
    height: size(2, m)
    background: $lh-green
    width: size(80, m)
    margin-top: .75em

  @include mq($from: tablet)
    &:after
      height: size(4)
      width: size(146)
      margin-top: .15em

.img--bordered
  position: relative
  display: inline-block
  margin-right: size(22, m)

  img
    display: block
    border-radius: size(8, m)
    position: relative
    z-index: 1

  &:after
    content: ''
    display: block
    position: absolute
    left: size(22, m)
    top: size(22, m)
    width: 100%
    height: 100%
    border: 2px solid $lh-green
    border-radius: size(8, m)
    
  @include mq($from: tablet)
    margin-right: size(40)
    border-radius: size(8)

    &:after
      left: size(40)
      top: size(40)
      border-radius: size(8)

.rel
  position: relative

.text
  line-height: 150%

  p
    margin-bottom: 1.7em

  @include mq($from: tablet)
    p
      margin-bottom: 1.1em

.text-accent
  color: $lh-accent

.text-accent-2
  color: $lh-accent-2

.text-green
  color: $lh-green

.text-action
  color: $lh-action

.text-caption
  text-transform: uppercase
  font-family: Poppins, sans-serif
  font-weight: 600


.bg-gray
  background: rgba(#E6E6EA, .5)