.partners-why
  padding-top: 5.6em
  padding-bottom: 3em

  &__title
    margin-bottom: .8em

  &__content
    margin-bottom: size(79, m)

  @include mq($from: tablet)
    padding-top: 6.5em
    padding-bottom: 4em

    &__container
      display: flex
      flex-direction: row-reverse
      align-items: center

    &__image
      flex: size(580) 0 0
      margin-right: auto

      .img--bordered
        width: 100%
        img
          width: size(580)
          display: block

    &__content
      margin-bottom: unset

    &__description
      font-size: size(20)
      width: size(507)
      margin-left: auto

    &__title
      margin-bottom: 2em
      width: size(507)
      margin-left: auto