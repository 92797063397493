.index-capabilities
  padding-top: 4.7rem
  padding-bottom: 5.9rem
  color: #FFF
  background: $lh-action

  &__title
    text-align: center
    margin-bottom: 2.15em
    
  .h-underlined
    &:after
      background: #FFF
      margin-left: auto
      margin-right: auto

  &__description
    font-size: size(20, m)
    text-align: center
    margin-bottom: 4.8rem

  &__slider
    margin-bottom: 4.25em
    margin-left: size(-16, m)
    margin-right: size(-16, m)

    .swiper-slide
      width: unset
      height: unset
      padding-left: size(16, m)
      display: flex

  &__button
    display: flex
    justify-content: center

  @include mq($from: tablet)
    padding-top: 6.3rem
    padding-bottom: 1.7rem

    &__container
      padding-left: size(260)
      padding-right: size(260)
      display: grid
      grid-template-columns: auto 1fr
      grid-template-rows: auto 1fr
      grid-template-areas: "header button" "content content"

    &__title
      text-align: unset
      margin-bottom: 1.95em

      .h-underlined
        &:after
          margin-left: unset
          margin-right: unset

    &__description
      font-size: size(20)
      width: size(518)
      text-align: unset
      margin-bottom: unset

    &__header
      margin-bottom: 4rem
      grid-area: header

    &__slider
      margin-right: unset
      margin-left: unset
      margin-bottom: unset

      .swiper-wrapper
        display: flex
        flex-wrap: wrap
        justify-content: space-between

      .swiper-slide
        padding-left: unset
        margin-bottom: size(83)

    &__content
      grid-area: content

    &__button
      grid-area: button
      justify-content: flex-end
      align-self: flex-end
      margin-bottom: 4rem