.careers-benefits
  padding-top: 5.7em
  padding-bottom: 5.7em
  background: #E6E6EA

  &__title
    margin-bottom: 3.2em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__list
    &-item
      margin-bottom: size(40, m)
      display: flex
      align-items: center

    &-icon
      flex: size(60, m) 0 0
      height: size(60, m)
      display: flex
      align-items: center
      justify-content: center
      margin-right: 1em

    &-title
      font-family: Poppins, sans-serif
      line-height: 150%
      color: #231F20
      font-weight: 500
      font-size: size(20, m)
      padding-top: .2em

  @include mq($from: tablet)
    padding-top: 6.5em
    padding-bottom: 3.5em

    &__title
      margin-bottom: 3.6em

    &__list
      display: flex
      justify-content: space-between
      flex-wrap: wrap

      &-item
        flex: size(360) 0 0
        margin-bottom: 2.2em

      &-title
        font-size: size(20)

      &-icon
        flex-basis: size(60)