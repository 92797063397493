.partners-ventures
  background: #D9D9D9
  padding-top: 5em
  padding-bottom: 2.375rem

  &__title
    margin-bottom: 1.4em

  &__description
    font-size: size(20, m)
    margin-bottom: 1.4em

  &__list
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-left: size(-8, m)
    margin-right: size(-8, m)

    &-item
      perspective: 1000px
      display: flex
      margin-bottom: size(40, m)
      width: size(300, m)
      margin-left: auto
      margin-right: auto
      min-height: size(350, m)

      &:hover
        .partners-ventures__list-content
          transform: rotateY(180deg)

    &-content
      position: relative
      width: 100%
      text-align: center
      transition: transform 0.8s
      transform-style: preserve-3d

    &-front, &-back
      position: absolute
      width: 100%
      height: 100%
      backface-visibility: hidden
      top: 0
      left: 0
      padding-bottom: 1em

    &-front
      background: #FFF
      color: #014E6B
      z-index: 2
      position: relative

    &-back
      transform: rotateY(180deg)
      background: #006194
      color: #FFF
      text-align: center
      display: flex
      align-items: center
      flex-direction: column
      
    &-link
      font-size: size(18, m)
      word-break: break-word
      padding-bottom: 2em

      a
        color: #FFF

    &-info
      flex: 1
      display: flex
      align-items: center
      padding: size(80, m) size(20, m)
      line-height: 150%

    &-description
      padding-left: size(20, m)
      padding-right: size(20, m)
      line-height: 150%
      font-family: Poppins, sans-serif
      font-weight: 500

    &-arrow
      position: absolute
      width: size(48, m)
      height: size(48, m)
      display: flex
      align-items: center
      justify-content: center
      background: $lh-action
      border-radius: 50%
      left: 50%
      top: 100%
      transform: translate(-50%, -50%)

      .icon
        fill: #FFF
        height: auto
        width: size(26, m)
        transform: rotate(-45deg)

    &-logo
      display: flex
      align-items: center
      justify-content: center
      height: size(180, m)
      width: 100%
      margin-bottom: size(20, m)

      img
        height: auto
        width: auto
        max-width: 80%
        max-height: 80%
        margin: auto

  @include mq($until: tablet)
    &__list
      &-logo
        img
          height: 7rem
          width: unset
          max-width: unset
          max-height: unset

  @include mq($from: tablet)
    padding-top: 6.3em
    padding-bottom: 2.9em

    &__description
      font-size: size(20)
      width: size(580)
      margin-bottom: 4.1em

    &__title
      margin-bottom: .9em

    &__list
      margin: unset

      &-item
        flex: size(300) 0 0
        min-height: size(350)

      &-logo
        height: 9.55rem

        img
          max-width: 100%
          max-height: 100%

      &-front, &-back
        padding-bottom: .7em

      &-description
        font-size: size(16)
        padding-left: size(20)
        padding-right: size(20)

      &-info
        font-size: size(16)
        padding: 1em size(20)
        margin-top: auto
        margin-bottom: auto
        flex: unset

      &-link
        font-size: size(20)
        padding-bottom: 3.3em