.careers-desk
  padding-top: 5em
  padding-bottom: 4.5em
  background: #063A59
  color: #FFF

  &__title
    margin-bottom: 2.4em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  &__description
    margin-bottom: 4.1em
    text-align: center

  &__slider
    padding-left: 1em
    padding-right: 1em

    .swiper-slide
      position: relative
      border-radius: size(8, m)
      overflow: hidden

      &:after
        content: ''
        padding-bottom: 61.56156%
        display: block

    img
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover

    .swiper-controls
      display: none

    .swiper-pagination
      position: unset
      padding-top: 1.6em

  @include mq($from: tablet)
    padding-top: 4.3em
    padding-bottom: 3.2em

    &__slider
      padding-left: size(130)
      padding-right: size(130)
      position: relative

      .swiper-pagination
        padding-top: .7em

      .swiper-controls
        display: flex
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        align-items: center
        pointer-events: none

      .swiper-button
        top: 50%
        transform: translateY(-50%)
        pointer-events: auto

        &-prev
          left: size(30)

        &-next
          right: size(30)

    &__description
      width: size(706)
      margin-left: auto
      margin-right: auto

    .swiper-slide
      &:after
        padding-bottom: 52.6226%

    &__title
      margin-bottom: 2em