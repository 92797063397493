.service-benefits
  padding-top: 5.2rem
  padding-bottom: 4.3rem

  &__title
    margin-bottom: 3em

  &__item
    display: flex
    align-items: center
    margin-bottom: 4.45em

  &__description
    margin-bottom: 6.1em
    line-height: 150%

  &__icon
    flex: 36.1% 0 0

    .icon
      height: auto
      width: size(100, m)

  &__caption
    margin-bottom: .4em

  &__items
    li
      padding-left: 1.5em
      position: relative
      padding-right: 1em

      &:after
        content: ''
        display: block
        width: size(4, m)
        height: size(4, m)
        border-radius: 50%
        background: currentColor
        position: absolute
        left: .65em
        top: .55em

  @include mq($from: tablet)
    padding-top: 6.4rem
    padding-bottom: 2.5rem

    &__container
      padding-left: size(160)
      padding-right: size(160)
      display: flex

    &__info
      flex: size(442) 0 0

    &__title
      margin-bottom: 2em

    &__list
      flex: size(496) 0 0
      margin-left: auto

    &__description
      line-height: 150%

    &__icon
      flex-basis: 28.4%

      .icon
        width: size(100)

    &__item
      align-items: flex-start
      margin-bottom: 3.1em

    &__items
      font-size: size(16)
      line-height: 150%

    &__caption
      margin-bottom: 0.6em