.index-impact
  padding-top: 4.8rem
  padding-bottom: 5.4rem

  &__title
    margin-bottom: 4em

    .h-underlined
      &:after
        margin-left: auto
        margin-right: auto

  .stats
    &__item
      margin-bottom: 1.8em

      &-value
        font-size: size(80, m)
        margin-right: unset
        margin-bottom: .25em
        display: flex
        justify-content: center
        min-width: 4.4em

        .count
          text-align: right
          font-feature-settings: "tnum"
          font-variant-numeric: tabular-nums
          display: inline-block

      &-label
        font-size: size(24, m)
        max-width: 90%
        margin-left: auto
        margin-right: auto

  @include mq($from: tablet)
    padding-top: 6.3rem
    padding-bottom: 4.1rem

    &__title
      margin-bottom: 5.6em

    &__list
      display: flex
      justify-content: space-around

    .stats
      &__item
        flex: size(300) 0 0

        &-label
          font-size: size(24)

        &-value
          margin-bottom: 0.1em